import React, {Component} from "react";
import {Link} from "react-router-dom";

import Bounce from "react-reveal/Bounce";

import Flash from "react-reveal/Flash";

class SureBuilt2025ConferenceInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.getElementById("header").scrollIntoView();
    }

    render() {
        let image_margin = "20px 0 0 0";
        let marginTop = "5%";

        if (window.innerWidth < 850) {
            image_margin = "-40px 0px 0px 0px";
            marginTop = "20%";
        }

        return (
            <div style={{ marginTop: "80px" }}>
                <section class="call-to-action-box no-padding">
                    <div class="container" style={{ marginBottom: "20px" }}>
                        <div class="action-style-box">
                            <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                                Conference Information - SURE-Built 2025
                            </h2>
                        </div>
                    </div>
                </section>

                <Bounce right fraction={0.01}>
                    <section id="main-container" class="main-container">
                        <div class="container" style={{marginTop: "-50px"}}>
                            <div className="row">


                                <div className="col-lg-6 mt-5 mt-lg-0">
                                    <div id="page-slider" className="page-slider small-bg"
                                         style={{margin: image_margin}}>
                                        <div
                                            className="item"
                                            style={{
                                                backgroundImage: "url(images/sure-built-2025-logo2.png)",
                                            }}
                                        ></div>
                                    </div>
                                </div>

                            </div>
                            <div class="row" style={{marginTop: marginTop}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Venue Information </h3>
                                    </Bounce>

                                    <p>
                                        <span
                                            style={{color: "red"}}>Online – Links will be shared with participants</span>
                                    </p>
                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Conference Fee </h3>
                                    </Bounce>
                                    <span style={{color: "red"}}>Free-To-Attend Conference – No Participation Fee</span><br/>


                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Organising committee </h3>
                                    </Bounce>

                                    <p>Prof Clinton Aigbavboa, UJ</p>
                                    <p>Prof Bankole Awuzie – UJ</p>
                                    <p>Dr Douglas Aghimien – UJ</p>
                                    <p>Dr (Mrs) Ntebo Ngcobo – UJ</p>
                                    <p>Dr (Mrs) Patience Tunji-Olayeni – CU</p>
                                    <p>Prof Ayodeji Oke - FUTA</p>
                                    <p>Dr Temitope Omotayo – LBU</p>

                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Conference Aim and Objectives</h3>
                                    </Bounce>
                                    <p>The primary objectives of this conference are strategically aligned to create a
                                        meaningful and lasting impact on the fields of architecture, engineering, and
                                        construction, as well as sustainable development, especially within emerging
                                        economies.

                                        <br/>The key objectives of the conference are as follows:</p>

                                    <ul>
                                        <li><span style={{color: "red"}}>Promote Inclusivity:</span> The conference will
                                            eliminate financial and geographic barriers to ensure diverse representation
                                            from underrepresented regions. By making attendance free of charge and
                                            accessible virtually, the conference will promote participation from a
                                            broader spectrum of scholars and professionals, enabling a truly global
                                            discourse on sustainability.
                                        </li>

                                        <li><span
                                            style={{color: "red"}}>Promote Interdisciplinary Collaboration:</span> Addressing
                                            complex issues in sustainable development requires contributions from
                                            multiple disciplines. This conference is designed to bring together experts
                                            from fields such as architecture, engineering, urban planning, environmental
                                            science, and policy to create an inclusive platform where knowledge and
                                            methodologies can be shared and integrated.
                                        </li>

                                        <li><span style={{color: "red"}}>Support Sustainable Development in Emerging Economies:</span> By
                                            aligning the conference themes and discussions with the Sustainable
                                            Development Goals, the conference is positioned to contribute tangible
                                            outcomes that directly impact communities. Emphasis on sustainable
                                            construction practices, resource management, and innovative policy
                                            approaches will ensure that the conference outputs are relevant and
                                            applicable to emerging economies' specific needs.
                                        </li>

                                        <li><span
                                            style={{color: "red"}}>Encourage Practical Innovation and Application:</span> The
                                            conference will showcase research, case studies, and practical projects that
                                            offer scalable, innovative solutions for challenges in emerging economies.
                                            This objective aligns with a commitment to bridging the gap between theory
                                            and application, making the conference's discussions insightful and
                                            actionable for local and global stakeholders.
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">ORGANISERS & CONFERENCE CHAIRPERSONS</h3>
                                    </Bounce>
                                    Professor Clinton Aigbavboa<br/>
                                    DSI/ NRF Research Chair (Interim) – Sustainable Construction Management and
                                    Leadership in the Built Environment, Faculty of Engineering and the Built
                                    Environment, University of Johannesburg, South Africa caigbavboa@uj.ac.za<br/>
                                    caigbavboa@uj.ac.za
                                    <p></p>
                                    Professor Bankole Awuzie<br/>
                                    Department of Construction Management and Quantity Surveying, University of
                                    Johannesburg, South Africa
                                    <br/>boawuzie@uj.ac.za
                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Contact</h3>
                                    </Bounce>

                                    For more information or any queries send an email to:<span
                                    style={{color: "red"}}> surebuilt2025@gmail.com</span>
                                </div>
                            </div>


                        </div>
                    </section>
                </Bounce>

                <section class="call-to-action-box no-padding">
                    <div class="container" style={{marginBottom: "20px"}}>
                        <div class="action-style-box">
                            <div class="row align-items-center">
                            <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference">
                                                {" "}
                                                <a class="btn btn-dark">Conference Overview</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference-submissions">
                                                {" "}
                                                <a class="btn btn-dark">Conference Submissions</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/">
                                                {" "}
                                                <a class="btn btn-dark">Back to CR Centre</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SureBuilt2025ConferenceInformation