import React, { Component } from "react";
import { Route, Redirect, withRouter, Link, Switch, BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import { easy_answer, unshuffled_dret, phd_studies, ongoing_studies, masters_studies, journals, books, book_chapters, industry_research } from "./extra_script";

import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Rotate from "react-reveal/Rotate";
import Flip from "react-reveal/Flip";
import Flash from "react-reveal/Flash";
import Pulse from "react-reveal/Pulse";
import Shake from "react-reveal/Shake";
import Tada from "react-reveal/Tada";
import Jump from "react-reveal/Jump";
import Spin from "react-reveal/Spin";
import Wobble from "react-reveal/Wobble";
import RubberBand from "react-reveal/RubberBand";
import Swing from "react-reveal/Swing";

import SureBuilt2025Conference from "./conferences/sure-built-2025/sure-built2025";
import SureBuilt2025ConferenceMenu from "./conferences/sure-built-2025/header-menu";
import SureBuilt2025ConferenceInformation from "./conferences/sure-built-2025/information-page";
import SureBuilt2025ConferenceSubmissions from "./conferences/sure-built-2025/submission-page";
import SureBuilt2025ConferenceProgramme from "./conferences/sure-built-2025/programme-page";
import get_surebuilt2025_images from "./conferences/sure-built-2025/header-image";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_url: "",
    };
  }

  componentDidMount() {
    setInterval(this.check_current_url, 1000);
  }

  check_current_url = () => {
    if (window.location.pathname !== this.state.current_url) {
      this.setState({ current_url: window.location.pathname });
    }
  };

  get_required_menu = () => {
    if (window.location.pathname.includes("2022-cidb")) {
      return <Cidb2022ConferenceMenu />;
    }

    if (window.location.pathname.includes("2023-sacqsp")) {
      return <Sacqsp023ConferenceMenu/>
    }

    if (window.location.pathname.includes("2025-sure-built")) {
      return <SureBuilt2025ConferenceMenu/>
    }

    return <PrimaryMenu />;
  };

  render() {
    return (
      <div>
        {/*header tag is neccesary below to envelop the header section  because the original html template is styld using the header tag*/}
        <header id="header" class="header-one">
          <SiteTitleSection />
          {this.get_required_menu()}
        </header>
        {/*header tag is neccesary below to envelop the header section  because the original html template is styled using the header tag*/}

        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/dret" component={DretTool} />
          <Route exact path="/about-centre" component={AboutCentre} />
          <Route exact path="/centre-team" component={TheTeam} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/phd-studies" component={PHDStudies} />
          <Route exact path="/masters-studies" component={MastersStudies} />
          <Route exact path="/ongoing-studies" component={OngoingStudies} />
          <Route exact path="/clinton-aigbavboa" component={Clinton} />
          <Route exact path="/liphadzi-murendeni" component={Liphadzi} />
          <Route exact path="/training" component={Training} />

          <Route exact path="/journals" component={Journals} />
          <Route exact path="/books" component={Books} />
          <Route exact path="/industry-research" component={IndustryResearch} />

          {/* 2022 cidb conference routes*/}
          <Route exact path="/2022-cidb-conference" component={Cidb2022Conference} />
          <Route exact path="/2022-cidb-conference-information" component={Cidb2022ConferenceInformation} />
          <Route exact path="/2022-cidb-conference-submissions" component={Cidb2022ConferenceSubmissions} />
          <Route exact path="/2022-cidb-conference-programme" component={Cidb2022ConferenceProgramme} />
          
          {/* 2023 cidb conference routes*/}
          <Route exact path="/2023-sacqsp-conference" component={Sacqsp2023Conference} />
          <Route exact path="/2023-sacqsp-conference-information" component={Sacqsp2023ConferenceInformation} />
          <Route exact path="/2023-sacqsp-conference-submissions" component={Sacqsp2023ConferenceSubmissions} />
          <Route exact path="/2023-sacqsp-conference-programme" component={Sacqsp2023ConferenceProgramme} />

          {/* 2025 SURE Built conference routes*/}
          <Route exact path="/2025-sure-built-conference" component={SureBuilt2025Conference} />
          <Route exact path="/2025-sure-built-conference-information" component={SureBuilt2025ConferenceInformation} />
          <Route exact path="/2025-sure-built-conference-submissions" component={SureBuilt2025ConferenceSubmissions} />
          <Route exact path="/2025-sure-built-conference-programme" component={SureBuilt2025ConferenceProgramme} />

           
          
        </Switch>

        <Footer />
      </div>
    );
  }
}



class Sacqsp2023Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
              CONFERENCE OVERVIEW - SACQSP 2023<br/>
              DIGITALISATION OF THE QUANTITY SURVEYING PRACTICE<br/> TOWARDS A SUSTAINABLE PROFESSION
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                  Organised by : </h4>
                  THE SOUTH AFRICAN COUNCIL FOR THE QUANTITY SURVEYING PROFESSION 
                  <h4 style={{ marginTop: "10px" }}>Hosted by : </h4>

                  DEPARTMENT OF CONSTRUCTION MANAGEMENT AND QUANTITY SURVEYING, UNIVERSITY OF JOHANNESBURG SOUTH AFRICA
                  
                 
                  <h4 style={{ marginTop: "10px" }}>Topic : </h4>
                  Digilisation of the Quantity Surveying Practise: Towards A Sustainable Profession
                  <hr/>
                  <h1 style={{ marginTop: "50px" }} >22nd – 23rd October 2023 </h1>

                  <h4>
                    Venue : The Maslow Hotel <br />
                    Cnr Grayston Drive & Rivonia Road, Sandton<br />
                    Johannesburg South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/sacqsp-background.png)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Background</h3>
                  </Bounce>

                  <p>
                  The quantity surveying (QS) profession is a critical profession in the construction industry with a long history. However, the profession has witnessed various transformations and has evolved over the ages. The profession has evolved with the industrial revolutions birthing new aspects and skills
to match the dynamic industry and client requirements. The current wave of the industrial revolution, driven by digital, physical and biological technologies as well as innovative building solutions, provides possible directions towards attaining a sustainable construction industry. Consequently, the QS profession is required to adapt and achieve sustainability. The adoption of advancements such as systems integration and the internet of things, virtual/ augmented/mixed realities, artificial intelligence, building information modelling, cloud computing, cyber security, big data and analytics, autonomous robots, simulations etc., impacts the traditional roles of the profession and requires the birthing of new roles and aspects.</p>
                  <p>
                  It is thus critical at this point in time when other professions are striving to incorporate emerging technologies into their roles for the QS profession to do likewise. Although considering the unique role and the fact the profession interfaces with all professionals in the industry, it is non-negotiable for the profession to be at par with the current industrial revolution by going digital, ensuring
its sustainability. This is quite important, especially in developing countries where technology adoption has been slow and very low compared to developed countries. In addition, the QS profession is one of the slowest and least responsive to technology adoption.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Important Dates</h3>
                  </Bounce>

                  <ul>
                    <li>Submission of Abstracts - 15 June 2023</li>

                    <li>Notification of Acceptance of Abstracts – 30 June 2023 </li>

                    <li>
                      Submission of Papers – 30 July 2023{" "}
                  
                    </li>

                    <li>Notification of Decision on Papers – 20 August 2023 </li>

                    <li>Submission of Revised Papers – 10 September 2023</li>

                    <span style={{color:"red"}}>Full papers submission are welcome by 15 June 2023</span>
                  </ul>
                </div>
              </div>

              {/* <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Keynote Speakers</h3>
                  </Bounce>

                  <ul>
                    <li>Mr Bongani Dladla - Acting CEO, cidb, South Africa</li>

                    <li>Mr Gregory Mofokeng, CEO, BBCBE</li>

                    <li>Professor Roger Flanagan – University of Readings, UK</li>

                    <li>Professor Chimay Anumba – University of Florida, USA</li>

                    <li>Professor Samuel Laryea – University of the Witwatersrand, RSA</li>

                    <li>Professor Nicholas Chileshe - University of South Australia, Adelaide, Australia</li>

                    <li>Professor David Edwards – Birmingham City University, UK</li>
                  </ul>
                </div>
              </div> */}

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">AWARDS</h3>
                  </Bounce>

                  <ul>
                    <li>ACADEMIC PAPER AWARD</li>

                    <li>OVERALL YOUTH PRESENTATION U/35 AWARD</li>

                    <li>YOUTH WOMAN PRESENTATION AWARD</li>
                    <li>DIGITALISATION & SUSTAINABILITY PAPER AWARD</li>
                    <li>OVERALL FIRST TIME PRESENTER AWARD</li>
                    <li>PRESENTATION & INNOVATION PAPER AWARD</li>
                    <li>INDUSTRY PAPER AWARD</li>
        
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Publication</h3>
                  </Bounce>

                  <p style={{ color: "red", fontWeight: "bold" }}>
                  1. DHET ready conference proceedings

                  </p>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                  
2. Selected best papers will be published in a DHET accredited special issue
                  </p>
                </div>
              </div>
              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  <p>For more information or any queries send an email to: sacqspsaci@gmail.com</p>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference-submissions">
                        {" "}
                        <a class="btn btn-dark">Paper Submissions</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}




class Cidb2022Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                CIDB 12th Postgraduate Conference, 10th – 12th, July 2022 International Convention Centre, East-London, South Africa
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                    CIDB 12th Postgraduate Conference First Announcement and Call for Papers (Physical and Virtual Conference)
                  </h4>

                  <h6>Towards a sustainable construction industry: the role of innovation and digitalisation</h6>
                  <h1 style={{ marginTop: "50px" }}>10th – 12th July 2022 </h1>

                  <h4>
                    Venue : International Convention Centre, <br />
                    East-London, South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/springer.jpg)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Background</h3>
                  </Bounce>

                  <p>
                    The unsustainable traditional approach to construction project delivery has necessitated the continuous call for more sustainable
                    construction industry - an industry that provides construction projects that encourage the judicious use of environmental resources, one
                    that promotes people's social well-being and also provides value for clients' investments. The current wave of industrial revolution driven
                    by digital, physical and biological technologies as well as innovative building solutions provides possible directions towards attaining a
                    sustainable construction industry. The adoption of advancements such as systems integration and the internet of things,
                    virtual/augmented/mixed realities, artificial intelligence, building information modelling, cloud computing, cyber security, big data and
                    analytics, autonomous robots, simulations etc., offer the construction industry opportunities for digital transformation. More so,
                    innovative building solutions such as modular integrated construction, self-healing concrete technology, additive manufacturing (3D
                    printing), smart constructions and various available innovative building materials all offer possible directions for the industry's
                    transformation into a more sustainable one.
                  </p>
                  <p>
                    The journey towards attaining a sustainable construction industry might not be smooth considering the different challenges bedevilling the
                    industry in both developing and developed countries. Therefore, understanding the different aspects of construction that need to be
                    reinforced and transformed through innovative solutions and digitalisation is crucial. Evidently, it is strategic at this point in time for
                    stakeholders to critically evaluate the construction industry from its resources (finance, human, material), technology, supply chain down
                    to the external construction environment. This is with a view of creating an innovative and digitalised industry needed for a more
                    sustainable construction industry. Therefore, it is appropriate and timely to explore and deliberate upon ways to achieve a more sustainable
                    construction industry, emphasising innovative solutions and digitalisation.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Important Dates</h3>
                  </Bounce>

                  <ul>
                    <li>Submission of Abstracts - 15 February 2022</li>

                    <li>Notification of Acceptance of Abstracts – 28 February 2022 </li>

                    <li>
                      Submission of Papers – 30 March 2022{" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        ------ <b> Extended to 15th April 2022 </b>
                      </span>{" "}
                    </li>

                    <li>Notification of Decision on Papers – 30 April 2022 </li>

                    <li>Submission of Revised Papers – 15 May 2022</li>
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Keynote Speakers</h3>
                  </Bounce>

                  <ul>
                    <li>Mr Bongani Dladla - Acting CEO, cidb, South Africa</li>

                    <li>Mr Gregory Mofokeng, CEO, BBCBE</li>

                    <li>Professor Roger Flanagan – University of Readings, UK</li>

                    <li>Professor Chimay Anumba – University of Florida, USA</li>

                    <li>Professor Samuel Laryea – University of the Witwatersrand, RSA</li>

                    <li>Professor Nicholas Chileshe - University of South Australia, Adelaide, Australia</li>

                    <li>Professor David Edwards – Birmingham City University, UK</li>
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">AWARDS</h3>
                  </Bounce>

                  <ul>
                    <li>Best paper award (general)</li>

                    <li>Best postgraduate paper award</li>

                    <li>Best industry paper award</li>
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Publication</h3>
                  </Bounce>

                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Conference Proceedings will be published in a Springer Edited Book Submitted for indexing to ISI Proceedings, EI-Compendex, DBLP, SCOPUS,
                    Google Scholar, and Springerlink.
                  </p>
                </div>
              </div>
              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  <p>Conference contact: cidb2022@gmail.com</p>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference-submissions">
                        {" "}
                        <a class="btn btn-dark">Paper Submissions</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Sacqsp2023ConferenceInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Information - SACQSP 2023
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Venue Information </h3>
                  </Bounce>

                  <h6>The Maslow Hotel</h6>

                  <h6>Cnr Grayston Drive & Rivonia Road, Sandton, South Africa </h6>

                  <p>
                  Located at the intersection of Grayston Drive and Rivonia Road, The Maslow Hotel in Sandton offers a unique blend of contemporary design and convenience. Ideal for both business and leisure travelers, this luxurious four-star hotel is in the heart of Sandton, known as Africa's richest square mile.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Conference Fee <span style={{color:"red"}}>(EXCL. VAT)</span> </h3>
                  </Bounce>
                  Delegate : <span style={{color:"red"}}>R3 500 </span><br/>
                  
                  Student Delegate :  <span style={{color:"red"}}>R1500</span><br/>

                  Accompanying Person (non-presenter) : <span style={{color:"red"}}>R1500</span><br/>
                  International delegate : <span style={{color:"red"}}>$300</span>
             
                  <p style={{color:"red"}}>PLEASE NOTE - BELOW: </p>
                  <p>
                  Accommodation is not included in the fee. Information on preferential rates provided by local hotels will be advertised on the conference website, which is under construction.
                  </p>
                  <p>
                  Registration covers one paper only, attendance to all program sessions, conference material, light lunches, tea and coffee breaks, welcome reception, opening ceremony, conference dinner, conference bag etc.
                  </p>
                  <p>Participants who wish to present and publish additional papers are expected to pay the additional paper fee of R1 500 per paper.</p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Organising committee </h3>
                  </Bounce>
                
                  <p>Prof Clinton Aigbavboa, UJ</p>
                  <p>Dr Ramabodu Molusiwa – UJ</p>
                  <p>Dr Liphadzi Murendeni – UJ</p>
                  <p>Dr Lerato Aghimien – UJ</p>
                  <p>Dr Matthew Ikuabe – UJ</p>
                  <p>Dr Samuel Adekunle - UJ</p>
                  <p>Mr Love David – UJ</p>
                  <p>Dr Ntakana Khululekani – UJ</p>
                  <p>Prof Nishani Harinarain - SACQSP</p>
                  <p>Mr Oscar Nkosi - SACQSP</p>
                  <p>Ms Onwaba Tselane - SACQSP</p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title"> SCIENTIFIC AND TECHNICAL REVIEW COMMITTEE (Not a complete list)</h3>
                  </Bounce>

                  







                  <ul>
                    <li>Prof David Edwards - 
Birmingham City University, UK</li>

                    <li>Prof Jasper Mbachu - 
Bond University, Australia</li>

                    <li>Prof Fidelis Emuze - 
Central University of Technology, RSA</li>

                    <li>Mr Roy Cumberlege - 
Nelson Mandela University, RSA</li>

                    <li>Prof Gerrit Crafford - 
Nelson Mandela University, RSA</li>

                    <li>Prof John Smallwood - 
Nelson Mandela University, RSA</li>

                    <li>Ms Karen Le Jeune - 
University of Cape Town, RSA</li>












                    <li>Prof Kathy Michell - 
University of Cape Town, RSA</li>
                    <li>Prof Paul Bowen - 
University of Cape Town, RSA</li>
                    <li>Prof Wellington Thwala - 
University of South Africa, RSA</li>
                    <li>Prof Nishani Harinarain - 
University of Kwa-Zulu Natal, RSA</li>
                    <li>Mrs Elzane van Eck - 
University of Pretoria, RSA</li>
                    <li>Prof Benita Zulch - 
University of Pretoria, RSA</li>
                    <li>Prof Hoffie Cruywagen - 
University of Pretoria, RSA</li>










                    <li>Dr Iniobong John - 
University of Lagos, Nigeria</li>
                    <li>Dr Iniobong John - 
University of Lagos, Nigeria</li>
                    <li>Dr Christopher Amoah - 
University of the Free State, RSA</li>
                    <li>Dr Fredrick Simpeh - 
Ghana</li>
                    <li>Mr Pierre Oosthuizen - 
University of the Free State, RSA</li>
                    <li>Mrs Mart-Mari Els - 
University of the Free State, RSA</li>
                    <li>Prof Kahilu Kajimo-Shakantu - 
University of the Free State, RSA</li>
                    <li>Prof David Root - 
University of the Witwatersrand, RSA</li>
                    
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">ORGANISERS & CONFERENCE CHAIRPERSONS</h3>
                  </Bounce>
                  Professor Clinton Aigbavboa<br/>
Director (Interim), Research Chair in Sustainable Construction Management and Leadership in the Built Environment, Faculty of Engineering and the Built Environment, University of Johannesburg <br/>
caigbavboa@uj.ac.za
                <p></p>
                  Dr Ramabodu Molusiwa<br/>
Head of Department, Department of Construction Management and Faculty of Engineering and the Built Environment, University of Johannesburg
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  For more information or any queries send an email to: sacqspsaci@gmail.com
                </div>
              </div>

              
              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                  Organised by : </h4>
                  THE SOUTH AFRICAN COUNCIL FOR THE QUANTITY SURVEYING PROFESSION 
                  <h4 style={{ marginTop: "10px" }}>Hosted by : </h4>

                  DEPARTMENT OF CONSTRUCTION MANAGEMENT AND QUANTITY SURVEYING, UNIVERSITY OF JOHANNESBURG SOUTH AFRICA
                  
                 
                  <h4 style={{ marginTop: "10px" }}>Topic : </h4>
                  Digilisation of the Quantity Surveying Practise: Towards A Sustainable Profession
                  <hr/>
                  <h1 style={{ marginTop: "50px" }} >22nd – 23rd October 2023 </h1>

                  <h4>
                    Venue : The Maslow Hotel <br />
                    Cnr Grayston Drive & Rivonia Road, Sandton<br />
                    Johannesburg South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/sacqsp-background.png)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference-submissions">
                        {" "}
                        <a class="btn btn-dark">Conference Submissions</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}




class Cidb2022ConferenceInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Information
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Venue Information </h3>
                  </Bounce>

                  <h6>International Convention Centre,</h6>

                  <h6>Marine Park Complex, 22 Esplanade, Beachfront, East-London, South Africa </h6>

                  <p>
                    East-London is a city on the Indian Ocean, in South Africa's Eastern Cape Province. It's known for its beaches, such as Nahoon and Cove
                    Rock. The Buffalo Pass is a road crossing a forested valley and the Buffalo River. Fossilised human and animal footprints, plus artefacts
                    from the Xhosa-speaking people, are exhibited at the East-London Museum. Elephants, lions, zebras and other wildlife roam in several nearby
                    game reserves.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Conference Fee </h3>
                  </Bounce>
                  Delegate R3 500 + VAT Student Delegate R1000 + VAT Accompanying Person R500 + VAT (non-presenters) International delegate $300 Online Delegate
                  $150 (Only International)
                  <p>
                    * Accommodation is not included in the conference fee. Information on preferential rates provided by local hotels will be advertised on the
                    conference website in due course.
                  </p>
                  <p>
                    * Registration covers one paper only, attendance to all program sessions, Conference material, light lunches, tea and coffee breaks, welcome
                    reception, opening ceremony, conference dinner, conference bag etc.
                  </p>
                  <p>*Participants who wish to present and publish additional papers are expected to pay the additional paper fee of $80/R1,000 per paper</p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Organising committee</h3>
                  </Bounce>

                  <p>Prof Clinton Aigbavboa, UJ – Committee Chair</p>
                  <p>Prof Didibhuku Thwala – UJ</p>
                  <p>Dr Douglas Aghimien – UJ</p>
                  <p>Dr Liphadzi Murendeni – UJ</p>
                  <p>Dr Olusegun Oguntona – WSU</p>
                  <p>Mr Siyabulela Dywili - WSU</p>
                  <p>Mrs Lerato Aghimien – UJ</p>
                  <p>Dr Ntebo Ngozwana – cidb</p>
                  <p>Ms Ntswaki Mokonyane - cidb</p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Keynote Speakers</h3>
                  </Bounce>

                  <ul>
                    <li>Mr Bongani Dladla - Acting CEO, cidb, South Africa</li>

                    <li>Mr Gregory Mofokeng, CEO, BBCBE</li>

                    <li>Professor Roger Flanagan – University of Readings, UK</li>

                    <li>Professor Chimay Anumba – University of Florida, USA</li>

                    <li>Professor Samuel Laryea – University of the Witwatersrand, RSA</li>

                    <li>Professor Nicholas Chileshe - University of South Australia, Adelaide, Australia</li>

                    <li>Professor David Edwards – Birmingham City University, UK</li>
                  </ul>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Main sponsors </h3>
                  </Bounce>
                  Construction Industry Development Board, South Africa
                  <br />
                  Dr Ntebo Ngozwana (Director: Research and Development)
                  <br />
                  Email: ntebon@cidb.org.za
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  <p>Conference contact: cidb2022@gmail.com</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                    CIDB 12th Postgraduate Conference First Announcement and Call for Papers (Physical and Virtual Conference)
                  </h4>

                  <h6>Towards a sustainable construction industry: the role of innovation and digitalisation</h6>
                  <h1 style={{ marginTop: "50px" }}>10th – 12th July 2022 </h1>

                  <h4>
                    Venue : International Convention Centre, <br />
                    East-London, South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/springer.jpg)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference-submissions">
                        {" "}
                        <a class="btn btn-dark">Conference Submissions</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}




class Sacqsp2023ConferenceSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Paper Submissions - SACQSP 2023
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Recommended Topics</h3>
                  </Bounce>

                  <p>The conference aims to provide an opportunity to review and create knowledge on achieving a sustainable QS profession through innovation and digitalisation. Consequently, delivering the resulting processes and practices needed to achieve a sustainable QS profession that is more effective and efficient by emphasising digitalisation.</p>

                  <p>
                    The papers submitted for the conference might explore topics included <b>but are not limited to</b> the following:
                  </p>


                  
  
 

  
 


                  <ul>
                    <li> Competitiveness</li>
                    <li>Sustainability</li>
                    <li>Dynamic capability</li>
                    <li>Pedagogical development</li>
                    <li> Business modelling</li>

                    <li>Value management</li>
                    <li>Professional ethics</li>
                    <li>Skills development</li>
                    <li>Data management</li>
                    <li>Commercial management</li>
                    <li>Smart contract </li>
                    <li>Contract management </li>
                    <li>Risk evaluation</li>
                    <li>Augmented/Virtual/Mixed realities in sustainable construction</li>
                    <li>Big data and the construction industry</li>


                    







                    <li> Carbon emissions and energy usage in the built environment  </li>
                    <li> Changing roles and responsibilities of quantity surveyors in sustainable construction projects  </li>
                    <li> Circular economy in construction  </li>
                    <li> Construction digitalisation Corporate social responsibility  </li>
                    <li> Digitalisation and construction human resource management  </li>
                    <li>  Efficient and sustainable use of construction materials </li>
                    <li>  Health, safety and well-being of construction workers and end- users </li>


                    







                    <li> Housing affordability and sustainable development problems  </li>
                    <li> Indoor environmental quality and management  </li>
                    <li> Information modelling and Digital Twin Technology (BIM, BrIM, CIM, GIS)  </li>
                    <li> Regulatory Framework for and Government Initiatives  </li>
                    <li>  Sustainable building design and refurbishment </li>
                    <li>  Sustainable supply chain management and procurement strategies in construction </li>
                    <li>  Sustainable waste management </li>
                    <li> Sustainable construction practices  </li>
                  
                  </ul>
                </div>
              </div>
              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Abstract and Paper Submission Information </h3>
                  </Bounce>

                  <p>
                  Please submit your abstract of not more than 300 words and include up to 5 keywords online via the google link{" "}
                    <a href="http://tiny.cc/sacqsp-submission-link" style={{ color: "red" }} target="blank">
                      {" "}
                      here{" "}
                    </a>
                  </p>

                  <p>
                    The abstract template can be assessed by clicking{" "}
                    <a href="http://tiny.cc/sacqsp-abstract-template" style={{ color: "red" }} target="blank">
                      here
                    </a>
                  </p>

                  <p>
                  Abstracts will be sent to two referees. Only the authors of abstracts that pass this peer-review process will be invited to submit full papers.{" "}
                  </p>
                  <p>The deadline for the submission of abstracts is 23:59 hrs GMT on 15 June 2023. </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Guidelines for Full Paper Submission:</h3>
                  </Bounce>
                  <p>
                  Full papers must not exceed 10 sides of A4 and should adhere to the conference guidelines paper template, which is available for download{" "}
                    <a href="http://tiny.cc/sacqsp-2023-full-paper" style={{ color: "red" }} target="blank">
                      here
                    </a>
                  </p>
                  <p>
                  Two members of the scientific committee will review each paper. If the paper is accepted, its authors will be invited to present the paper at the conference. At least one of the authors must be able to attend the conference.{" "}
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  <p>For more information or any queries send an email to: sacqspsaci@gmail.com</p>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                  Organised by : </h4>
                  THE SOUTH AFRICAN COUNCIL FOR THE QUANTITY SURVEYING PROFESSION 
                  <h4 style={{ marginTop: "10px" }}>Hosted by : </h4>

                  DEPARTMENT OF CONSTRUCTION MANAGEMENT AND QUANTITY SURVEYING, UNIVERSITY OF JOHANNESBURG SOUTH AFRICA
                  
                 
                  <h4 style={{ marginTop: "10px" }}>Topic : </h4>
                  Digilisation of the Quantity Surveying Practise: Towards A Sustainable Profession
                  <hr/>
                  <h1 style={{ marginTop: "50px" }} >22nd – 23rd October 2023 </h1>

                  <h4>
                    Venue : The Maslow Hotel <br />
                    Cnr Grayston Drive & Rivonia Road, Sandton<br />
                    Johannesburg South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/sacqsp-background.png)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>


              
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Cidb2022ConferenceSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Paper Submissions
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Recommended Topics</h3>
                  </Bounce>

                  <p>
                    The papers submitted for the conference might explore topics included <b>but are not limited to</b> the following:
                  </p>

                  <ul>
                    <li>Augmented/Virtual/Mixed Realities in sustainable construction</li>
                    <li>Big data and the Construction industry</li>
                    <li>Carbon emissions and energy usage in the built environment</li>
                    <li>Changing roles and responsibilities of project stakeholders in sustainable construction projects</li>
                    <li> Circular economy in construction</li>
                    <li>Construction digitalisation</li>
                    <li>Corporate Social Responsibility </li>
                    <li>Digitalisation and construction human resource management</li>
                    <li>Efficient and sustainable use of construction materials</li>
                    <li>Health, safety and well-being of construction workers and end-users </li>
                    <li>Housing affordability and sustainable development problems </li>
                    <li>Indoor environmental quality and management </li>
                    <li>Information modelling and Digital Twin Technology (BIM, BrIM, CIM, GIS)</li>
                    <li>Innovative Building Materials for sustainable construction</li>
                    <li>Innovative Building Technologies for sustainable construction</li>
                    <li>Innovative financing of sustainable infrastructure</li>
                    <li>Land use planning and sustainable development</li>
                    <li>Material management and environmental concerns </li>
                    <li>Material re-use in infrastructure development</li>
                    <li>Pollution in the urban environment</li>
                    <li>Regulatory Framework for and Government Initiatives</li>
                    <li>Sustainable building design and refurbishment</li>
                    <li>Sustainable supply chain management and procurement strategies in construction </li>
                    <li>Sustainable waste management </li>
                    <li>Sustainable construction practices</li>
                    <li>Value management and sustainable practice </li>
                  </ul>
                </div>
              </div>
              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Abstract and Paper Submission Information </h3>
                  </Bounce>

                  <p>
                    Please submit your abstract of not more than 300 words and include up to 5 keywords online via the google link{" "}
                    <a href="https://forms.gle/tFEzuQKezVegBUY99" style={{ color: "red" }} target="blank">
                      {" "}
                      here{" "}
                    </a>
                  </p>

                  <p>
                    The abstract template can be assessed by clicking{" "}
                    <a href="http://tiny.cc/cidb-abstract-template" style={{ color: "red" }} target="blank">
                      here
                    </a>
                  </p>

                  <p>
                    Abstracts will be sent to two referees. Only the authors of abstracts that pass this peer-review process will be invited to submit full
                    papers.{" "}
                  </p>
                  <p>The deadline for the submission of abstracts is 23:59hrs GMT on 15 February 2022 </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Guidelines for Full Paper Submission:</h3>
                  </Bounce>
                  <p>
                    Full papers must not exceed 10 sides of A4 and should adhere to the cidb 2019 conference guidelines paper template, which is available for
                    download{" "}
                    <a href="http://tiny.cc/cidb-full-paper-template" style={{ color: "red" }} target="blank">
                      here
                    </a>
                  </p>
                  <p>
                    Two members of the scientific committee will review each paper. If the paper is accepted, its authors will be invited to present the paper
                    at the conference. At least one of the authors must be able to attend the conference.{" "}
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Contact</h3>
                  </Bounce>

                  <p>Conference contact: cidb2022@gmail.com</p>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                    CIDB 12th Postgraduate Conference First Announcement and Call for Papers (Physical and Virtual Conference)
                  </h4>

                  <h6>Towards a sustainable construction industry: the role of innovation and digitalisation</h6>
                  <h1 style={{ marginTop: "50px" }}>10th – 12th July 2022 </h1>

                  <h4>Venue : International Convention Centre, East-London, South Africa.</h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/springer.jpg)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


class Sacqsp2023ConferenceProgramme extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Programme - SACQSP 2023
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: "4%" }}>
                <div class="col-lg-12">
                  <Bounce delay={1000} right>
                    <h1 class="column-title">Conference Programme Overview Will be Available Soon</h1>
                  </Bounce>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                  Organised by : </h4>
                  THE SOUTH AFRICAN COUNCIL FOR THE QUANTITY SURVEYING PROFESSION 
                  <h4 style={{ marginTop: "10px" }}>Hosted by : </h4>

                  DEPARTMENT OF CONSTRUCTION MANAGEMENT AND QUANTITY SURVEYING, UNIVERSITY OF JOHANNESBURG SOUTH AFRICA
                  
                 
                  <h4 style={{ marginTop: "10px" }}>Topic : </h4>
                  Digilisation of the Quantity Surveying Practise: Towards A Sustainable Profession
                  <hr/>
                  <h1 style={{ marginTop: "50px" }} >22nd – 23rd October 2023 </h1>

                  <h4>
                    Venue : The Maslow Hotel <br />
                    Cnr Grayston Drive & Rivonia Road, Sandton<br />
                    Johannesburg South Africa.
                  </h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/sacqsp-background.png)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2023-sacqsp-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}



class Cidb2022ConferenceProgramme extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Conference Programme
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: "4%" }}>
                <div class="col-lg-12">
                  <Bounce delay={1000} right>
                    <h1 class="column-title">Conference Programme Overview Will be Available Soon</h1>
                  </Bounce>
                </div>
              </div>

              <div class="row" style={{ marginTop: "180px" }}>
                <div class="col-lg-6">
                  <h4 style={{ marginTop: "50px" }}>
                    CIDB 12th Postgraduate Conference First Announcement and Call for Papers (Physical and Virtual Conference)
                  </h4>

                  <h6>Towards a sustainable construction industry: the role of innovation and digitalisation</h6>
                  <h1 style={{ marginTop: "50px" }}>10th – 12th July 2022 </h1>

                  <h4>Venue : International Convention Centre, East London, South Africa.</h4>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/springer.jpg)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference">
                        {" "}
                        <a class="btn btn-dark">Conference Overview</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/2022-cidb-conference-information">
                        {" "}
                        <a class="btn btn-dark">Conference Information</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/">
                        {" "}
                        <a class="btn btn-dark">Back to CR Centre</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Journals extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Published Journals
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {journals.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.name}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Journal Authors</a>
                              </span>
                              {" | "}
                              <b>{item.aurthors}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Year Published
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link to="/phd-studies">
                    <a class="btn btn-primary"> See Completed PHD Research</a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}
class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Books
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {books.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.name}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Journal Authors</a>
                              </span>
                              {" | "}
                              <b>{item.aurthors}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Year Published
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                              <p></p>
                              <a class="btn btn-primary" href={item.url} target="blank">
                                {" "}
                                View Book Here{" "}
                              </a>
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Book Chapters
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {book_chapters.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.name}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Journal Authors</a>
                              </span>
                              {" | "}
                              <b>{item.aurthors}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Year Published
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}

class IndustryResearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Industry Research
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {industry_research.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.name}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Journal Authors</a>
                              </span>
                              {" | "}
                              <b>{item.aurthors}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Year Published
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                              <p></p>
                              <a class="btn btn-primary" href={item.url} target="blank">
                                {" "}
                                View Here{" "}
                              </a>
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}

class OngoingStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Ongoing Research
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {ongoing_studies.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.topic}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Author</a>
                              </span>
                              {" | "}
                              <b>{item.name}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Projected Completion Date
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link to="/phd-studies">
                    <a class="btn btn-primary"> See Completed PHD Research</a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}

class PHDStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Completed PHD Research
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {phd_studies.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.topic}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Author</a>
                              </span>
                              {" | "}
                              <b>{item.name}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Projected Completion Date
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link to="/ongoing-studies">
                    <a class="btn btn-primary"> See Ongoing Research</a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}

class MastersStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginTop: "80px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Completed Masters Research
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0 order-0 order-lg-1" style={{ marginTop: "-110px" }}>
                  <div class="post">
                    {masters_studies.map((item) => (
                      <div class="post-body">
                        <hr />
                        <div class="entry-header">
                          <h2 class="entry-title">
                            <a>{item.topic}</a>
                          </h2>

                          <p></p>

                          <Bounce left fraction={0.01}>
                            <div class="post-meta">
                              <span class="post-author">
                                <i class="far fa-user"></i>
                                <a> Author</a>
                              </span>
                              {" | "}
                              <b>{item.name}</b>
                              <br />
                              <span class="post-meta-date">
                                <i class="far fa-calendar"></i> Projected Completion Date
                              </span>
                              {" | "}
                              <b>{item.year}</b>
                              <br />
                            </div>
                          </Bounce>
                        </div>

                        <div class="entry-content">
                          <p>
                            {
                              //item.abstract
                            }
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link to="/ongoing-studies">
                    <a class="btn btn-primary"> See Ongoing Research</a>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </>
    );
  }
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this function below is needed because of issues with original html template, it has to be recalled everything the component that uses it is to be rendered
    this.re_render_original_template_javascript();
  }

  re_render_original_template_javascript = () => {
    // this function below is needed because of issues with original html template, it has to be recalled everything the component that uses it is to be rendered
    var script = document.createElement("script");
    script.src = "js/script.js";
    document.body.appendChild(script);
  };

  render() {
    return (
      <div>
        <Bounce down>
          <BigBanner />
        </Bounce>

        <FancyYellowBox />

        <Bounce left fraction={0.5}>
          {/*<BelowFancyYellow />*/}
          <ConferenceDisplay />
        </Bounce>

        <BlackAcheivementBlock />
        <ConstructionWorker />
        <YellowAndBlackBlock />
        <BelowYellowAndBleckBlock />
      </div>
    );
  }
}

class AboutCentre extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                ABOUT CR CENTRE
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row">
                <div class="col-lg-6">
                  <p>
                    The Sustainable Human Settlement and Construction Research Centre (SHSCRC), is an entity of the University of Johannesburg within the
                    Department of Construction Management and Quantity Surveying and subject to the governance rules and structures of the Faculty of
                    Engineering and Built Environment of the University of Johannesburg. The centre brings together a unique team of inter-disciplinary experts
                    who are experienced in delivering high quality research and consultancy in the field of Sustainable Human Settlement (housing and public
                    policy).
                  </p>

                  <p>
                    The centre fulfill its Human Settlement (sustainable housing) research agenda through the preparation of working papers, research notes,
                    major reports, and by supporting the National Human Settlement Department agenda including its allied programmes, such as workshops, annual
                    conferences and symposia to extend and deepen the understanding of particular topics as deemed necessary.
                  </p>
                </div>

                <div class="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" class="page-slider small-bg" style={{ margin: image_margin }}>
                    <div
                      class="item"
                      style={{
                        backgroundImage: "url(images/services/service5.jpg)",
                      }}
                    >
                      <div class="container">
                        <div class="box-slider-content">
                          <div class="box-slider-text">
                            <h2 class="box-slide-title" style={{ color: "white" }}>
                              {" "}
                              SHSCRC
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: marginTop }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">Centre Location</h3>
                  </Bounce>

                  <p>
                    The Sustainable Human Settlement and Construction Research Centre (SHSCRC) - sustainable housing is the official housing research and
                    information center for housing development in South Africa. The SHSCRC provide services to the National Department of Human Settlement,
                    Provincial Human Settlement Departments, Municipalities, Metros, agencies, nonprofit organizations, and for-profit businesses. The custodian
                    of the Centre is the Executive Dean of the Faculty of Engineering and Built Environment (FEBE). The Centre is situated on the Doornfontein
                    Campus within the Department of Construction Management and Quantity Surveying.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">MISSION AND GOALS</h3>
                  </Bounce>

                  <p>
                    In SHSCRC, our mission is to serve as an interdisciplinary research and information source on housing (human settlement) development in
                    South Africa. In carrying out this mission, SHSCRC will work closely with the National Department of Human Settlement, Provincial Human
                    Settlement Departments, Municipalities, Metros, businesses and non-profit organisations to provide research-based information and technical
                    assistance.
                  </p>

                  <p>
                    The SHSCRC’s vision is to create and leverage cutting-edge research to improve the affordability, finance, sustainability, delivery
                    mechanisms, satisfaction, post occupancy experience, human settlement governance issues, stakeholders’ management, housing investment,
                    housing economics, programme and project management, property management, property economics, policy analysis, policy formulation, and
                    quality of housing in South Africa amongst others. Also, the SHSCRC will be committed to implementing a research agenda that will address
                    and find solutions to the most critical housing / human settlement needs of South Africa Metros and the nation in general.
                  </p>

                  <p>
                    The SHSCRC will be policy-relevant, theoretically informed and will centre on inter- disciplinary understandings of housing, home and the
                    entire human settlement spectrum. Our research will be influenced by theories of power, good governance, community engagement theories,
                    policy analysis, inequalities as well as practice theory and ideas relating to the human settlement body of knowledge. Overall, our mission
                    is to develop high-quality, policy/practice- relevant research for better housing/ human settlement and urban outcomes.
                  </p>
                </div>
              </div>

              <div class="row" style={{ marginTop: "2%" }}>
                <div class="col-lg-12">
                  <Bounce left>
                    <h3 class="column-title">RESEARCH AND SERVICES</h3>
                  </Bounce>

                  <p>
                    The SHSCRC will provide publically available, easily accessible housing and human settlement development data. The centre will produce and
                    published reports on topics such as housing markets and affordability; homeownership trends, preservation of subsidised
                    (government-assisted), low-income housing; post occupancy evaluation studies, rental housing, housing finance, housing satisfaction studies
                    across all spectrum of human settlement studies, regulatory impacts on affordable housing; public-private housing partnerships; and
                    diffusion of innovative housing technologies in residential construction, energy consumption in housing, stakeholders’ management, programme
                    and project management, policy analysis, policy formulation, leadership in human settlement development and adequate quality of housing
                    amongst others. In addition, SHSCRC will offer research-based information, case study guide and identification as well as technical
                    assistance to government agencies, municipalities, businesses and non-profit organizations.
                  </p>

                  <p>
                    The Centre will undertake, support, disseminate and popularise research, analysis and theorisation of human settlement policies, patterns,
                    systems amongst others. To this end it will:
                  </p>

                  <ul>
                    <li>Undertake multi-disciplinary Human Settlement research;</li>

                    <li>
                      Publish research findings, analysis, commentary, debates and theorisation through multiple formats, but especially articles in accredited
                      journals and academic books;
                    </li>

                    <li>Produce a regular editorial to be tagged: The State of the Nation’s Housing (Human Settlement);</li>

                    <li>Produce a bi-annual scientific journal on the field of Sustainable Human Settlement and Sevelopment;</li>

                    <li>
                      Support the supervision of post-graduate (M & D) students and post-doctoral fellows who will be engaged in various research agenda as
                      conceptualised by the centre;
                    </li>

                    <li>Provide an enabling environment for a community of researchers to engage in Human Settlement issues by providing solutions;</li>

                    <li>Organise and support seminars, workshops and conferences;</li>

                    <li>Provide briefings and commentary for the media where necessary on Human Settlement issues;</li>

                    <li>Engage with the civil society as deemed necessary in Human Settlement matters;</li>

                    <li>Offer policy advice to the National Department of Human Settlement, Provincial and Local Government</li>

                    <li>
                      Collaborate with others in advancing research in Humans Settlement, being pro-active in internationalising and localising research in this
                      field;
                    </li>

                    <li>Undertake human settlement community projects;</li>

                    <li>Recruit international and local researchers as associates;</li>

                    <li>Contribute to the University of Johannesburg Global Excellence and Status (GES) goals;</li>

                    <li>Ensure high ethical and professional standards in conducting its activities;</li>
                    <li>Participate in relevant academic, professional, research and civil society associations and committees; and</li>
                    <li>Offer training/ short courses on Sustainable Human Settlements body of knowledge.</li>
                    <li>Undertake multi-disciplinary Human Settlement research;</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <h3 class="action-title" style={{ color: "black" }}>
                      Research Team
                    </h3>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/centre-team">
                        {" "}
                        <a class="btn btn-dark">See Research Team</a>{" "}
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class TheTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "10px" }}>
        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container pb-4">
            <div class="container">
              <div class="row text-center">
                <div class="col-lg-12">
                  <h3 class="section-sub-title">Our Team</h3>
                </div>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">SHSCRC Centre Directors</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Clinton.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Professor Clinton Aigbavboa</h3>

                      <p class="ts-description">
                        Professor Clinton O. Aigbavboa is a Full Professor of Sustainable Human Development in the Department of Construction Management and
                        Quantity Surveying, University of Johannesburg, South Africa; with a multidisciplinary research focus on the built environment. Before
                        entering academia, he was involved as quantity surveyor on several infrastructural projects, both in Nigeria and South Africa. He holds
                        a PhD in Engineering Management and has published over 500 research papers in his areas of interest. He has extensive knowledge in
                        practice, research, training and teaching.
                      </p>
                    </div>
                    <Link to="/clinton-aigbavboa">
                      <a class="btn btn-dark">View Complete Profile</a>
                    </Link>
                  </div>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/liphadzi.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Liphadzi Murendeni</h3>

                      <p class="ts-description">
                        Dr Liphadzi is a researcher and a lecturer at the University of Johannesburg. He developed a leadership development framework in the
                        South African construction industry as part of his PhD studies. He is a registered candidate with the South African Council of Quantity
                        Surveyors, and he contributes professionally in the built environment.
                      </p>
                      <Link to="/liphadzi-murendeni">
                        <a class="btn btn-dark">View Complete Profile</a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">Visiting Professors</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/thwala.jfif" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Prof. Didibhuku Wellington Thwala</h3>

                      <p class="ts-description">
                        Professor Wellington Didibhuku Thwala is a Professor at the University of South Africa (UNISA). He was a Professor of Construction
                        Project Management and Masters Programme Coordinator at the Department of Construction Management and Quantity Surveying, University of
                        Johannesburg prior to moving to UNISA. Professor Thwala has more than 100 published and peer-reviewed journals, chapters in books and
                        conference proceedings locally and internationally. He is the former Editor of the Journal of Construction. He is the current
                        Editor-in-Chief of the Journal of Construction Project Management and Innovation (JCPMI).
                      </p>
                    </div>
                  </div>
                </div>
               
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Douglass.jpeg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">DR DOUGLAS OMOREGIE AGHIMIEN </h3>
                      <p class="ts-designation">Post-Doctorial Reasearcher</p>
                      <p class="ts-description">Dr Douglas Aghimien is a Lecturer at the Department of Civil Engineering Technology, University of Johannesburg (UJ), South Africa. 
                      He is a recipient of the UJ Postdoctoral Fellows Research Excellence Awards for the best performing postdoctoral fellow from the Faculty of Engineering and the
                       Built environment for 2021. His research interests include construction digitalisation, construction performance, value management, sustainability in construction,
                        and construction procurement. He has published more than 100 articles in reputable peer-reviewed journals, books chapters, and conference proceedings. 
                        He is the lead author of the book ‘Construction Digitalisation — A capability maturity model for construction organisations’ published by Routledge.
                         In addition, he is a deputy editor of the Journal of Construction Project Management and Innovation.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Temidayo.jpeg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Temidayo Osunsanmi </h3>
                      <p class="ts-designation">Post-Doctorial Reasearcher</p>
                      <p class="ts-description">
                        {" "}
                        Dr Temidayo Osunsanmi is a postdoctoral fellow in construction management at the department of construction management and quantity
                        surveying, University of Johannesburg, South Africa. He completed his PhD in Engineering Management at the University of Johannesburg in
                        2020. His research interest lies in construction supply chain management, housing, the fourth industrial revolution and the gig economy.
                        Much of his work has been focused on improving the economic conditions of Africa. He has received over 400,000 South African Rands of
                        internal and external funds for his research. Dr Temidayo is the author of over 30 published research articles and is currently writing
                        a book on construction supply chain management in Africa. He has a strong passion for assisting young African researchers to achieve
                        their full potential.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">Post-Doctorial Researchers</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/John.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. John Aliu </h3>
                      <p class="ts-designation">Post-doctorial Researcher</p>
                      <p class="ts-description">
                        John Ogbeleakhu Aliu is currently a Post-Doctoral Research Fellowship at the CIDB Centre of Excellence, Faculty of Engineering and the
                        Built Environment at the University of Johannesburg, South Africa. He recently completed his PhD studies at the Department of Civil
                        Engineering Science, at the University of Johannesburg, and had his Master’s Degree in Construction Management at the same university.
                        As a young researcher, he is passionate about seeking new knowledge and improving his existing skill set in both learning and teaching.
                        His research interests includes construction education, engineering education, skills development, educational technologies, educational
                        approaches, employability studies and technological innovations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Edward.jpeg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Edward John Cobbina </h3>
                      <p class="ts-designation">Post-Doctorial Researcher</p>
                      <p class="ts-description">
                        "Edward John Cobbina is a PhD holder currently working with the Koforidua Technical University in Ghana... He completed his PhD in 2020
                        from the University of Johannesburg, South Africa. He did his master in Engineering Project Management from the Coventry university, UK
                        in 2012 and his Bachelors Degree in Building Technology from the Kwame Nkrumah University of Science and Technology in Kumasi... He has
                        gain several industry experience working as a Projects Manager and Quantities Surveyors on several roads and building construction
                        projects."
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-6 mb-5"> 
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Andrew.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Andrew Ebekozien </h3>
                      <p class="ts-designation">Post-Doctorial Researcher</p>
                      <p class="ts-description">
                        Dr Andrew Ebekozien is a Senior Research Associate with Department of Construction Management and Department of Quantity Surveying, with
                        a PhD degree in Cost Management from Universiti Sains Malaysia, Master's, Post Graduate Diploma, and Higher National Diploma in Quantity
                        Surveying. He was former Head, Quantity Surveying Department, Auchi Polytechnic, Nigeria from 2012 to 2016. He is a corporate member and
                        registered member of the Nigerian Institute of Quantity Surveyors and Quantity Surveyors Registration Board of Nigeria.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Matthew.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Matthew Kwaw Somiah </h3>
                      <p class="ts-designation">Post-Doctorial Researcher</p>
                      <p class="ts-description">
                        Matthew Kwaw Somiah is a senior lecturer at Takoradi Technical University, with PhD in Civil Engineering, Masters in Construction
                        Management and Bachelor's in Building Technology. He is currently pursuing his Postdoctoral studies at the University of Johannesburg,
                        South Africa. He has been a lead researcher for CoST Infrastructure Transparency International Assurance studies in Ghana.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">PhD Graduates</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Olusegun.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Olusegun Aanuoluwapo Oguntona </h3>
                      <p class="ts-designation">PhD Graduate</p>
                      <p class="ts-description">
                        Olusegun Oguntona is a Senior Lecturer in the Department of Built Environment at the Walter Sisulu University, South Africa. He
                        graduated from the Ladoke Akintola University of Technology, Nigeria with a Bachelor of Technology (Hons.) in Architecture. He completed
                        his MTech (with distinction) and PhD in Construction Management from the University of Johannesburg, South Africa. Dr Oguntona was the
                        recipient of The Chancellor’s Medal for the most meritorious Master’s study in the Faculty of Engineering and the Built Environment for
                        the 2017 academic year. He is a member of Project Management South Africa (PMSA) and the South African Council for the Project and
                        Construction Management Professions (SACPCMP).{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Emmanuel.png" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Dr. Emmanuel Yaw Frempong Jnr</h3>
                      <p class="ts-designation">PhD Graduate</p>
                      <p class="ts-description">
                        Dr. Emmanuel Frempong Jnr is an associate member of the Ghana institute of architects and a lecturer at the Koforidua technical
                        university in Ghana. He holds a PhD degree in engineering management (Univ. of Johannesburg-SA), Masters degree in Construction
                        Management and Economics (Univ. of Greenwich-UK) and both Post Graduate diploma and Honours degree in Architecture (KNUST-GH)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">PhD Candidates</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Aloysius.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Aloysius Sam </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        Aloysius Sam is a Lecturer at Department of Buildiing Technology, Kumasi Technical University (KsTU) Ghana. A PhD candidate at
                        University of Johannesburg SouthAfrica, Master's in Construction Management and BTech in Buildiing Technology. He has also completed
                        General Course in Construction and Construction Technician Certificate Part I and II.His area of specialisation include; Construction
                        Management, Measurement of Construction Works Construction Technology and Procurement. He is also Managing Director of ASMAK CONTRACTORS
                        LTD{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Ademilade.png" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Ademilade Aboginije</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Ademilade Aboginije is an ardent researcher with keen interest in sustainable built environment and construction materials. He holds
                        bachelor degree in Civil Engineering from Federal University of Technology, Akure, Nigeria. He obtained his master's degree in
                        Construction Management with distinction. He is currently working as a doctoral researcher at the department of Civil Engineering
                        Science, University of Johannesburg.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Benjamen.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Benjamen Sunkanmi Adeyemi</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Benjamen Sunkanmi Adeyemi is a PhD student in the department of Construction management and Quantity Surveying, Faculty of Engineering
                        and the Built Environment, University of Johannesburg, South Africa. He holds MTech in Construction Management at the University of
                        Johannesburg, Johannesburg South Africa. He also holds a BTech in Quantity Surveying from Federal University of Technology, Akure Ondo
                        State, Nigeria. He holds some professionals certificates in some online courses offered by cousera. He is currently undergoing a
                        leadership course sponsored by University of Johannesburg Young Leadership Program (UJYDLP). He is currently a member of University of
                        Johannesburg House committee.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Tshepo.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Tshepo Phuti Mabotja</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Tshepo Mabotja has completed Masters degree in Operations Management, he has enrolled for PhD in Operations management, he hold a B-tech
                        degree in Logistics Management from Vaal University of Technology, He is currently a lecture at CPUT, he also at the same time hold a
                        Position of Inventory & Systems Manager at CIPLA, its a pharmaceutical company. He is also a Principal Owner and Chairman of Maluts
                        Engineering Solutions. He has managed to hold multiple positions in FMCG & Mining sectors among other positions across his 16 year
                        cooperate sector experience.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Opeoluwa.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Opeoluwa Akinradewo</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Opeoluwa is a PhD candidate in Construction Management at the University of Johannesburg where he bagged his M.Tech in Quantity
                        Surveying with distinction in 2019. He developed an interest in research during his undergraduate days at the Federal University of
                        Technology, Akure (FUTA), which spurred him into writing articles and representing FUTA at the NIQS organised ReCon2 Student Essay
                        Writing and Presentation competition in which he emerged as the winner in 2015. Based on his current interest in blockchain technology,
                        he participated in the 2020 blockchain technology summer school hosted by the University of Zurich. He is also a certified blockchain
                        architect by Blockchain Council, USA.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Ifedolapo.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Engr. Ifedolapo H. Babalola </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Babalola H. I. is a lecturer in the Department of Project Management Technology, at the Federal University of Technology Akure, Ondo
                        State, Nigeria. She is currently a doctoral student in Construction Management. She had a masters in Construction Engineering and a
                        Bachelor of Engineering in Civil Engineering.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/David.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">David Ngwoke Mbazor</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        David Ngwoke Mbazor is a Lecturer in the Department of Estate Management at the Federal University of Technology, Akure, Nigeria. He
                        holds B. Tech. in Estate Management and Valuation from the Federal University of Technology, Minna, Nigeria; Master of Business
                        Administration from University of Calabar, Nigeria, and MSc in International Project Management from the Glasgow Caledonian University,
                        Scotland, the UK. He is a registered Estate Surveyor and Valuer and a member Project Management Institute (PMI). His research interests
                        are situated in the fields of sustainable housing development and management with focus on housing quality delivery, real estate
                        development, land administration and property/facility management. He is currently a Doctoral Candidate at SARChi Center of Sustainable
                        Construction Management and Leadership in the Built Environment, Faculty of Engineering and the Built Environment, University of
                        Johannesburg, South Africa.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Mwewa.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Mwewa Mambwe </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        Mwewa Mambwe is currently a doctoral candidate in Construction Management with the SARCHi in Sustainable Construction Management and
                        Leadership, in the Department of Construction Management and Quantity Surveying. She has a Master of Engineering in Project Management
                        from University of Zambia, Bachelor's Degree in Real Estate and a Diploma in Surveying (Geomatics) from the Copperbelt University. She
                        also has a Diploma in Business Administration and an International Diploma in Intellectual Property from World Intellectual Property
                        Organization. She is a qualified Safety Health Environment and Quality (SHEQ) Auditor from IRCA Global Certification. Etc. She is a
                        registered engineer and a member of Zambia Network for Environmental Educators and Practitioners.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Ogunbayo.jpeg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Ogunbayo Babatunde Fatai </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Ogunbayo Babatunde Fatai holds a Ph.D. Degree in Construction Management, Master’s in Housing, Bachelor of Science in Quantity
                        Surveying, and Higher National Diploma in Building Technology. He is a Member of the Nigerian Institute of Building and Council of
                        Registered Builders of Nigeria. He has worked in the public and private sectors. Presently, working as a Lecturer at the Department of
                        Construction Management and Quantity Surveying, Faculty of Engineering and Built environment, University of Johannesburg, South Africa.
                        He is a professional Construction Manager, and a Member of the Cidb Centre of Excellence and Sustainable Human Settlement, and
                        Construction Research Centre
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/tlotlo.png" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Tlotlo K Ramasu </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Tlotlo K Ramasu is a PhD candidate in Operations Management in the field of SME growth, an M-tech in Operations Management in the field
                        of Theory of Constraints and a National Diploma in Food Technology. She has also completed a short learning programme in Project
                        management for Engineers with Rice University, and Business Implications of AI with EIT Digital in 2020.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Khotso.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Khotso Dithebe </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        {" "}
                        Khotso Dithebe is a PhD candidate in the Department of Construction Management and Quantity Surveying, Faculty of Engineering and Built
                        Environment, with a Masters and Btech in Construction Management. He has also completed a short learning programme in Artificial
                        Intelligence in the 4IR, and currently enrolled for a short learning programme in African Insights with the University of Johannesburg.
                        In the year 2019 he was awarded chancellor's award and SARCHI sponsored award for the most meritorious master's study in the faculty.
                        Currently in addition to his doctoral studies he works as a co-supervisor for Bsc honour's research.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Linda.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Linda Malifete </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Linda Malifete is a PhD candidate at the University of Johannesburg. She obtained her Master’s degree in Operations Management in 2019
                        from the University of Johannesburg, in which she assessed the impact of Lean manufacturing in the South African airline industry in her
                        dissertation. She published a conference proceeding to IEOM community with a title “A theoretical analysis of Lean implementation in
                        airline industry” in 2018. She has a very strong passion for quality, continuous improvement, flair for solving problems, ensuring
                        smooth operation of services, processes and to improve customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Ntwanano.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Ntwanano Mueti</h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        "Ntwanano Mueti is a highly dependent, capable and meticulous individual with comprehensive background in areas of Change Management
                        Consulting environment. Currently studying a PhD degree in Operations Management, with Master's in Operations Management, Management
                        Consulting for Productivity Improvement, Bachelor of Science Technology Management, Quality Management System implementation- ISO
                        9001:2008, Lean Six Sigma Green Belt, Bachelor’s Degree in Operations Management, Certificate in Advance Project Management and National
                        Diploma in Operations Management. "
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Chigozie.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Chigozie Collins Okafor </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        Chigozie Collins Okafor is a registered PhD candidate in the department of Construction Management, University of Johannesburg, South
                        Africa. He holds a B.Eng degree in Civil Engineering and M.Eng degree in Materials and Construction Management. He holds a Certificate
                        of Honor on Building Information Modelling from BIM Academy, BRICS Business Council and Worldskills Russia. He recently completed a
                        short learning course on Big Data, Artificial Intelligence, and Ethics from the University of California, Davis, USA in 2020. His
                        current research focus is on the concept of smart city and it's related ontology, in relation to it's application.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/John Aiyesehinde.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">John Aiyesehinde </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        {" "}
                        John Aiyesehinde is a broadcast transmissions professional and an aspiring operations management consultant, with a a Bachelor's degree
                        in Mechanical Engineering, a Master's in Engineering Management and currently a PhD candidate in Operations Management. He has also
                        completed several online short courses and trainings, some of which includes the TCP/IP Training Course, Lean Six Sigma white-belt and
                        Yellow-belt training courses as well as the Artificial Intelligence in the 4IR introductory course.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Oluwaseun.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Oluwaseun Tope Akinbogun </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        {" "}
                        Mrs. Akinbogun is a Ph.D student in the department of operation management in the faculty of engineering and built environment, her
                        master of strategic project management was obtained from the herriot watt University in Edinburgh Scotland UK and her B.Sc. in business
                        administration from Lagos State University, Ojo Lagos. Mrs Akinbogun is a lecture in the department of project management technology,
                        school of logistics and Innovation Technology, Federal University of Technology. Her research and teaching interest are construction
                        project management based, she has publications in journals and conferences both locally and internationally.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Samuel.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Samuel Adeniyi Adekunle </h3>
                      <p class="ts-designation">Phd Candidate</p>
                      <p class="ts-description">
                        {" "}
                        Samuel Adekunle is a professionally registered Quantity surveyor and an academia; he has extensive experience in research and practice.
                        He obtained his Bachelors and Masters degrees in Quantity surveying. Currently, he is studying for his PhD with a focus on construction
                        digitisation and automation at the department of Construction Management and Quantity Surveying, University of Johannesburg, South
                        Africa. Samuel is passionate about the implementation and diffusion of digitisation tools in the construction industry. Currently, he is
                        helping construction organisations and the industry in achieving maturity through a developed BIM maturity framework.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Nomzamo.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Nomzamo Mnqeta </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        {" "}
                        Nomzamo Mnqeta is an Infrastructure delivery specialist and strategic project manager with more than 17 years of experience in managing
                        projects and programmes within the National, Provincial and Local government. She is the Chief Director: Project Management Office
                        within the Municipal Infrastructure Support Agency. She has studied locally and internationally with Diploma in Building Science, B-
                        Tech Construction Management, Masters in Project Management (University of Technology, Sydney) including an Executive Certificate in
                        Infrastructure Delivery Management Programme and is also registered as a Project Management Professional (PMP) with the Project
                        Management Institute (PMI).
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Matthew Ikuabe.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Matthew Ikuabe </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        {" "}
                        IKUABE Matthew Osivue is a Lecturer in the Department of Quantity Surveying, University of Benin, Benin City, Nigeria. He holds a
                        Bachelor of Technology (B.Tech) and a Master of Technology (M.Tech) in Quantity Surveying both from the Federal University of
                        Technology, Akure, Nigeria. He is currently undergoing his Ph.D. in Construction Management at the University of Johannesburg, South
                        Africa; He has published research works in both local and international journals. He is a corporate member of the Nigerian Institute of
                        Quantity Surveyors (NIQS).
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Rudzani.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Rudzani Glen Muthelo </h3>
                      <p class="ts-designation">PhD Candidate</p>
                      <p class="ts-description">
                        Rudzani Muthelo is a candidate PhD student at the University of Johannesburg, having completed a master's degree in Quantity Surveying
                        and a National Diploma in Building. He is a registered Professional Construction Project Manager with SACPCMP and candidate Quantity
                        Surveyor with SACQSP.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row text-center">
                <div class="col-lg-12">
                  <h6 class="section-sub-title">Masters Candidates</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/Peter.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Peter A. Adekunle </h3>
                      <p class="ts-designation">Masters Candidate</p>
                      <p class="ts-description">
                        Peter Adekunle is currently undergoing his MSc. in Quantity Surveying at the University of Johannesburg. He graduated from the Federal
                        University of Technology, Akure in the year 2018. He worked for one year as graduate assistant at the prestigious Obafemi Awolowo
                        University between 2019 and 2020.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/team/sandile.png" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Sandile Mhlungu </h3>
                      <p class="ts-designation">Masters Candidate</p>
                      <p class="ts-description">
                        {" "}
                        Sandile Mhlungu is a creative and a digital technologies enthusiast currently holding a Bachelor of Technology Degree in Applied Design
                        (Architectural Technology), and a Bachelor of Technology Degree in Construction Management, also currently completing a Master’s Degree
                        in Construction Management. Sandile is currently working as a Programme Coordinator at Rosebank College with 4 years’ experience in the
                        academic space. He has also completed a short learning programme in Computational Intelligence for industry in January 2020. He is also
                        doing short courses in BIM coordination with the different software around Built-Information Modelling as his interests align around the
                        different technologies available.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <h3 class="action-title" style={{ color: "black" }}>
                      About CR Centre
                    </h3>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/about-centre">
                        <a class="btn btn-dark">Learn About Centre</a>
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Clinton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "10px" }}>
        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container pb-4">
            <div class="container">
              <div class="row text-center">
                <div class="col-lg-12">
                  <h3 class="section-sub-title">Professor Clinton Aigbavboa</h3>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/clinton2.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">SHSCRC Director</h3>
                      <p class="ts-designation"></p>
                      Faculty of Engineering and the Built Environment Department of Construction Management and Quantity Surveying
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-sm-6 mb-5">
                <div class="ts-team-wrapper">
                  <div class="ts-team-content-classic">
                    <h3>Contact Details</h3>
                    Auckland Park Campus: B1 Lab 27 │Doornfontein Campus: Maropeng 198 <br />
                    Emails: caigbavboa@uj.ac.za | clinton@aigbavboa.com | aigclinton@gmail.com | <br />
                    Cell Number: +27-78 795 8231 | +27-73-062 3475
                    <p></p>
                    <h3>Biography</h3>
                    Clinton Aigbavboa is a Professor of Sustainable Human Development, with a PhD degree in Engineering Management, Master’s in Construction
                    Management and Higher National Diploma in Quantity Surveying. He has also completed a short learning programme in Good Governance in Africa
                    from the Thabo Mbeki Africa Leadership Institute at the University of South Africa (UNISA) and an Executive leadership course in Artificial
                    Intelligence: Implications for Business Strategy from the Massachusetts Institute of Technology (MIT), USA in January 2020.
                    <p></p>
                    Starting at UJ as a part-time lecturer in February 2007, he moved through the academic ranks quickly and was promoted to the position of
                    Full Professor in October 2018. He is ranked among the top three researchers in the Faculty of Engineering and the Built Environment and the
                    University of Johannesburg as a whole (since 2014 till date) owing to his exceptional research publication output, supervision of students,
                    and the attraction of external grants to the university. He currently serves as the Director: Sustainable Human Settlement and Construction
                    Research Centre, having formerly served as the Vice-Dean for Postgraduate Studies, Research and Innovation in the Faculty of Engineering and
                    the Built Environment (FEBE), at the University of Johannesburg from August 2017 to November 2019.
                    <p></p>
                    <h3>Postgraduate Supervision</h3>
                    He has successfully graduated over eighty (80) Master’s students and thirty-five (35) PhD students. Six (6) of his currently enrolled PhD
                    students were the students he mentored from the BTech level. Together with Professor Wellington Thwala, his mentor, he is presently
                    supervising forty-eight (48) Master’s students and twenty-two (22), doctoral candidates. Likewise, he has supervised/ hosted ten (10)
                    postdoctoral researchers to completion and is currently jointly hosting five (5) postdoctoral researchers. Prof Aigbavboa has collaborated
                    with experts in the construction industry, both locally and internationally, to create new knowledge and provide practical solutions in the
                    built environment. Furthermore, he is self-motivated, driven by excellence and his passion for practical research in the built environment
                    and its improvement is reflective of his current research projects in the subject of biomimicry and digitalization of the construction
                    industry. He also has a wide range of work experience from consulting firms and construction companies, through universities, to
                    governments.
                    <p></p>
                    <h3>Qualifications</h3>
                    HND (Auchi Poly.); MTECH (UJ); PhD (UJ); Certificate in Good Governance (UNISA), Executive Program – Artificial Intelligence: Implication
                    for Business Strategy(MIT, USA)
                    <p></p>
                    <h3>Rating as a Scientist: NRF, Scopus, Google Scholar & ResearchGate</h3>
                    <ul>
                      <li> NRF Rated - Y2 (Emerging Researcher, less than 40 years category): 2015-present (Re-evaluation submitted in 2021)</li>
                      <li>
                        {" "}
                        Google Scholar:
                        <a href="http://tiny.cc/Aigbavboa-Google-Scholar" target="blank" style={{ color: "red" }}>
                          {" "}
                          http://tiny.cc/Aigbavboa-Google-Scholar
                        </a>
                      </li>
                      <li>
                        Scopus:
                        <a href="http://tiny.cc/Clinton-Aigbavboa-Scopus" target="blank" style={{ color: "red" }}>
                          {" "}
                          http://tiny.cc/Clinton-Aigbavboa-Scopus
                        </a>
                      </li>
                      <li>
                        ResearchGate:{" "}
                        <a href="https://www.researchgate.net/profile/Clinton_Aigbavboa" target="blank" style={{ color: "red" }}>
                          https://www.researchgate.net/profile/Clinton_Aigbavboa
                        </a>
                      </li>
                    </ul>
                    <p></p>
                    <h3>Research Interests</h3>
                    His key expertise and interest areas span across multidisciplinary domains of sustainable human development, with the focus on sustainable
                    housing regeneration (urban renewal and informal housing), Life Cycle Assessment in the Construction Industry, remanufacturing, leadership
                    in low-income housing, sustainable construction thinking, biomimicry, digitalization of the construction industry, infrastructure
                    development, construction industry development, construction and engineering management, construction industry development and research
                    methodological thinking and paradigm, post-occupancy evaluation, green job creation and artificial intelligence and its application for
                    solving complex real-world problems in the built environment sector. Besides his professional industrial engagements, he is an active
                    researcher and has published and presented several peer-reviewed papers at national and international conferences and journals. During the
                    past six years since his doctoral graduation, Prof Aigbavboa has authored and co-authored more than 600 accredited journal articles (ISI,
                    IBSS, Scopus and DHET Indexed), conference papers, and book chapters. He is also the author of twelve (12) research books that were
                    published by Springer Nature and CRC Press. In addition, he is also a co-author of two edited book about the fourth industrial revolution
                    and sustainable education the construction industry that was published by Springer Nature in 2019 and 2021 respectively.
                    <p></p>
                    Supervision of postgraduate degrees (Master's and PhD)
                    <p class="ts-description"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <h3 class="action-title" style={{ color: "black" }}>
                      SHSCR Centre Team Menbers
                    </h3>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/centre-team">
                        <a class="btn btn-dark">View Team</a>
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
class Liphadzi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "10px" }}>
        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container pb-4">
            <div class="container">
              <div class="row text-center">
                <div class="col-lg-12">
                  <h3 class="section-sub-title">Dr. Liphadzi Murendeni</h3>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-6 mb-5">
                  <div class="ts-team-wrapper">
                    <div class="team-img-wrapper">
                      <img loading="lazy" src="images/liphadzi.jpg" class="img-fluid" alt="team-img" />
                    </div>
                    <div class="ts-team-content-classic">
                      <h3 class="ts-name">Deputy Director: cidb Centre of Excellence </h3>
                      <p class="ts-designation"></p>
                      University of Johannesburg (UJ) <br />
                      Faculty of Engineering and the Built Environment <br />
                      Department of Construction Management and Quantity Surveying
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-sm-6 mb-5">
                <div class="ts-team-wrapper">
                  <div class="ts-team-content-classic">
                    <h3>Contact Details</h3>
                    DFC Campus : 6th Floor <br />
                    Email: mliphadzi@uj.ac.za <br />
                    Cell Number: 011 559-6014
                    <p></p>
                    <h3>Biography</h3>
                    Dr Liphadzi is a researcher and a lecturer at the University of Johannesburg. He developed a leadership development framework in the South
                    African construction industry as part of his PhD studies. He is a registered candidate with the South African Council of Quantity Surveyors,
                    and he contributes professionally in the built environment.
                    <p></p>
                    <h3>Postgraduate Supervision</h3>
                    Currently supervises masters and doctoral research
                    <p></p>
                    <h3>Qualifications</h3>
                    MTech (UJ), PhD (UJ)
                    <p></p>
                    <h3>Research Interests</h3>
                    One of his research interest is Innovative leadership in the construction industry, moreover, with his executive duties in facility
                    management,, he has further taken research interest in amendments of the sectional title management act of South Africa. He has scientific
                    excitement in comparing theory with data, and developing some understanding of global changes that are occurring in the leadership research
                    industry.
                    <p class="ts-description"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>

        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <h3 class="action-title" style={{ color: "black" }}>
                      SHSCR Centre Team Menbers
                    </h3>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <Flash count={2}>
                      <Link to="/centre-team">
                        <a class="btn btn-dark">View Team</a>
                      </Link>
                    </Flash>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    let image_margin = "20px 0 0 0";
    let marginTop = "5%";

    if (window.innerWidth < 850) {
      image_margin = "-40px 0px 0px 0px";
      marginTop = "20%";
    }

    return (
      <div style={{ marginTop: "80px" }}>
        <section class="call-to-action-box no-padding">
          <div class="container" style={{ marginBottom: "20px" }}>
            <div class="action-style-box">
              <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                Trainings & Capacity Development
              </h2>
            </div>
          </div>
        </section>

        <Bounce right fraction={0.01}>
          <section id="main-container" class="main-container">
            <div class="container" style={{ marginTop: "-50px" }}>
              <div class="row" style={{ marginTop: "4%" }}>
                <div class="col-lg-12">
                  <Bounce delay={700} right>
                    <h1 class="column-title">Training Programme and Schedule Will be Available Soon</h1>
                  </Bounce>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </div>
    );
  }
}
class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //this.re_render_original_template_javascript();
  }

  re_render_original_template_javascript = () => {
    var map_script = document.createElement("script");
    map_script.src = "plugins/google-map/map.js";
    map_script.defer = true;

    document.body.appendChild(map_script);

    // this function below is needed because of issues with original html template, it has to be recalled everything the component that uses it is to be rendered
    var map_api_script = document.createElement("script");
    map_api_script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBilsJUCfkU6tEC-jZPE1nvGwjoOwo1aVE&callback=initialize";
    map_api_script.async = true;
    map_api_script.id = "map_api_script";

    if (document.getElementById("map_api_script") === null) {
      document.body.appendChild(map_api_script);
    } else {
      window.initialize();
    }
  };

  submit_contact_form = () => {
    window.event.preventDefault();

    alert("Can't send yet, Get Origin Email Info from Prof.");
  };

  render() {
    return (
      <div>
        <div class="body-inner">
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row text-center">
                <div class="col-12">
                  <h3 class="section-sub-title">Contact Information</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fas fa-map-marker-alt mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4> Our Address</h4>
                      <p>House No 6, Research Village, Bunting Road Campus, University of Johannesburg.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-envelope mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Email Us</h4>
                      <p>nsempe@uj.ac.za</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="ts-service-box-bg text-center h-100">
                    <span class="ts-service-icon icon-round">
                      <i class="fa fa-phone-square mr-0"></i>
                    </span>
                    <div class="ts-service-box-content">
                      <h4>Call Us</h4>
                      <p>Ms Nonhlanhla Sempe
                      </p>
                      <p>0115591860</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gap-60"></div>

              {/** the portion commented out is for google maps and contact us form
              <div class="google-map">
                <div
                  id="map"
                  class="map"
                  data-latitude="40.712776"
                  data-longitude="-74.005974"
                  data-marker="images/marker.png"
                  data-marker-name="Constra"
                ></div>
              </div>

              <div class="gap-40"></div>

              <div class="row">
                <div class="col-md-12">
                  <h3 class="column-title">We will love to hear from you</h3>

                  <form id="contact-form" action="#" method="post" role="form">
                    <div class="error-container"></div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Name</label>
                          <input class="form-control form-control-name" name="name" id="name" placeholder="" type="text" required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Email</label>
                          <input class="form-control form-control-email" name="email" id="email" placeholder="" type="email" required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Subject</label>
                          <input class="form-control form-control-subject" name="subject" id="subject" placeholder="" required />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea class="form-control form-control-message" name="message" id="message" placeholder="" rows="10" required></textarea>
                    </div>
                    <div class="text-right">
                      <br />
                      <button class="btn btn-primary solid blank" type="submit" onClick={() => this.submit_contact_form()}>
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              */}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

class DretTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_element_id: "",
      current_variable_id: "",
      current_variable_name: "",
      current_question: "",
      random_options: [],
      selected_response: "",
      current_question_index: "",

      completed_dret: [],

      show_current_page: "",
      dret_detail_info: false,
      company_name: "",

      exit_welcome_page: true,
      exit_company_page: true,
      exit_statement: true,
    };
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();

    this.initialise_dret_original_value();
    //this.setState({completed_dret :  dret })
    setTimeout(this.get_next_question_and_answer_block_data, 200);
    setTimeout(this.clean_dret_of_empty_options, 500);
  }

  initialise_dret_original_value = () => {
    let dret = [
      {
        variable_id: "technology_1",
        element_id: "technology",
        variable_name: "Technology planning",
        question: "Which of the following technology planning statement best fit your organisation?",
        response: "",
        options: [
          "Our organisation is still sceptical about the outcomes of adopting digital technologies",
          "The plan to digitally transform our organisation's business function using emerging digital technologies is at the infancy stage",
          "There are carefully thought out plans in place to ensure the  adoption of emerging digital technologies within our organisation",
          "Our organisation understands the requirement needed for digitalisation of our business function and effort have been made to ensure all resources needed are available",
          "Our organisation continues to improve on its techgnology planning and signifcant outcomes are being derived through the used of well planned digital technology adoption.",
        ],
      },

      {
        variable_id: "technology_2",
        element_id: "technology",
        variable_name: "Optimisation of Emerging technologies e.g BIM",
        question:
          "Please select the statement that is most applicable to your organisation in respect to the extent of adoption and optimisation of emerging digital technologies.",
        response: "",
        options: [
          "Very few digital technologies are adopted  for the sole purpose of easing our day-to-day organisational functions.",
          "My organisation is in a learning phase and as such only few digital technologies are adopted with the intent of digitising  organisational functions.",
          "My organisation understand the need to  adopt emerging digital technologies  and plans towards adopting them to digitalise the organisation's services.",
          "We have set quantifiable goals for digital transofrmation of the organisation, and significant improvement in construction productivity is being made through the use of digital technologies. ",
          "My organisation is a digital disruptor and it leads the construction industry discussion on digital transformation.",
        ],
      },

      {
        variable_id: "technology_3",
        element_id: "technology",
        variable_name: "Big data analytics",
        question: "Please select the statement that is most applicable to your organisation in respect to big data analytics.",
        response: "",
        options: [
          "Our organisation has no defined data analystics system ",
          "Our organisation understands the need to analyse data from past projects, but do not have the capability to correctly mine and analyse the data",
          "Our organisation have the digital technologies needed to mine and analyse data.",
          "Our organisation's decision-making is largely data-driven. ",
          "In our organisation, there is a continuous improvement in data analytics ",
        ],
      },

      {
        variable_id: "technology_4",
        element_id: "technology",
        variable_name: "Additive manufacturing (3D Printing)",
        question: "Please select the statement that is most applicable to your organisation in respect to the use of additive manufacturing.",
        response: "",
        options: [
          "Presently, our organisation lacks the capability to adopt 3D printing and we are sceptical about the inherent benefits",
          "Our organisation understands the importance of 3D printing in construction and we are willing to learn from repeated adoption. However, we are challenged by limited resources",
          "Our organisation understands the importance of 3D printing in construction. Clear policy regarding the adoption of this technology are already in place.",
          "Our organisation's prioritises the use of 3D printing in delivering more efficient construction projects.",
          "Our organisation has been able to optimise the use of 3D printing in our delivery of construction projects.",
        ],
      },

      {
        variable_id: "technology_5",
        element_id: "technology",
        variable_name: "Cloud computing/Connected things ",
        question: "Please select the statement that is most applicable to your organisation in respect to the use of cloud computing.",
        response: "",
        options: [
          "Our organisation has no defined data storage system and the use of internet is majorly of small office functions ",
          "Our organisation use technology such as computers and hardrives to store data and assess to any form of computing must be done within the organisation",
          "Our data storage and computing process is done over the internet ('the cloud')",
          "In our organisation, computing resoruces and data storage are centralised and easily shared for faster and more effeicent information exchange in improved productivity. ",
          "In our organisation, there is a continuous improvement in the use of cloud computing ",
        ],
      },

      {
        variable_id: "technology_6",
        element_id: "technology",
        variable_name: "Design simulation and visualisation",
        question:
          "Please select the statement that is most applicable to your organisation in respect to the use of design simulation and visualisation tools.",
        response: "",
        options: [
          "Presently, the adoption of simulation and visualisation tools is at the early planning stage in our organisation",
          "Our organisation understands the importance of design simulation and visualisation and we are willing to learn from repeated adoption. However, we are challenged by limited resources",
          "Our organisation understands the importance of design simulation and visualisation. Clear policy regarding the adoption of related technologies are already in place.",
          "Our organisation prioritises the use of design simulation and visualisation tools in creating a better experience for our clients",
          "Our organisation has been able to optimise the use of design simulation and visualisation tools in our  delivery of quality services to our clients. ",
        ],
      },

      {
        variable_id: "technology_7",
        element_id: "technology",
        variable_name: "Robotics and automation in construction",
        question: "Please select the statement that is most applicable to your organisation in respect to the use of robotics and automation.",
        response: "",
        options: [
          "Currently, our organisation lacks the capability to adopt robotics and automation and we are sceptical about the inherent benefits",
          "Our organisation understands the importance of robotics and automation in construction and we are willing to learn from repeated adoption. However, we are challenged by limited resources",
          "Our organisation understands the importance of robotics and automation in construction. Clear policy regarding the adoption of this technology are already in place.",
          "Our organisation's prioritises the use of robotics and automation in delivering more efficient construction projects.",
          "Our organisation has been able to optimise the use of robotics and automation in our  delivery of construction projects. ",
        ],
      },

      {
        variable_id: "technology_8",
        element_id: "technology",
        variable_name: "Cybersecurity",
        question: "Which of the following cybersecurity statement best fit your organisation?",
        response: "",
        options: [
          "Our organisation is in the process of acquiring basic computer security softwares needed ",
          "Our organisation is equipped with the basic computer security needed for data and information protection",
          "Our organisation knows the importance of data and information protection and has plans towards ensuring up-to-date cybersecurity systems are available.",
          "Our organisation have a clear defined policy on cybersecurity and has up-to-date cybersecurity systems.",
          "Improvement on data and infromation protection is continous in our organisation.",
        ],
      },

      {
        variable_id: "technology_9",
        element_id: "technology",
        variable_name: "Digital media marketing",
        question: "Which of the following digital media marketing statement best fit your organisation?",
        response: "",
        options: [
          "Our organisation is still sceptical about the possible outcomes of adopting digital platforms to market its products and services ",
          "There are plans to use digital platforms to market our organisation's products and services ",
          "Our organisations is using diverse digital platforms to market its  products and services",
          "Our organisations improves on its products and servcies through feedback from digital platforms",
          "Our organisations has been able to optimise the use of digital platforms for the continous marketing of its products and services",
        ],
      },

      {
        variable_id: "technology_10",
        element_id: "technology",
        variable_name: "Continuous technology upgrades",
        question: "Please select the statement that is most applicable to your organisation in respect to continuous technology upgrades",
        response: "",
        options: [
          "Technology usage is based on needs and availability, hence no upgrade is needed",
          "Plans for technology upgrade in our organisation is still at its infancy stage",
          "Technology upgrade in our organisation is done based on availability of resources and need",
          "Our organisation has strict guidelines for evaluation of the performance of adopted technologies and their upgrades",
          "Technology upgrade in our organisation is continous.",
        ],
      },

      {
        variable_id: "technology_11",
        element_id: "technology",
        variable_name: "Synergy between digital technology and other organisation’s resources",
        question:
          "Please select the statement that is most applicable to your organisation in respect to the Synergy between digital technology and other organisation’s resources",
        response: "",
        options: [
          "Plans to link digital technologies with other organisation's resources are in still at the infancy stage in our organisations",
          "Plans to link digital technologies with other organisation's resources are in advance stage in our organisations",
          "There is a clear synergy between digital technologies and all other organisation resources.",
          "Our organisations is improving on the use of digital technologies along side other resources to be more productive ",
          "Our organisation has been able to optise the synergy between digital technologies and other business opertations for improved innovativeness",
        ],
      },

      ////////////////begin people

      {
        variable_id: "people_1",
        element_id: "people",
        variable_name: "Digital technical know-how of personnel",
        question: "Please select the statement that is most applicable to your organisation in respect to the digital technical know-how of personnel.",
        response: "",
        options: [
          "Our organisation just began plans to have the required skilled personnel to handle digital tecghnologies ",
          "Our organisation already understands the need for adequate skilled personnel to handle digital technologies and plans are in place to get the right skilled personnel needed.",
          "Skilled personnel are available to handle digital technologies in our organisation  ",
          "Our organisation emphasises the productivity of skilled personnel and enhances this through clear performance evaluation. ",
          "In our organisation, continuous development is encouraged in improving the digital skills of workers  ",
        ],
      },

      {
        variable_id: "people_2",
        element_id: "people",
        variable_name: "Digital knowledge management by the organisation",
        question: "Which of the following statement regarding digital knowledge management best fit your organisation?",
        response: "",
        options: [
          "Plans to adopt digital technologies that will aid proper management of knowledge that will enable our organisation become digital players are at the early stages.",
          "Plans to adopt digital technologies that will aid proper management of knowledge that will enable our organisation become digitalplayers are at advance stages.",
          "In our organisation, digital technologies are used to effectively manage the knowledge gained from projects and this  knowledge is used to digitally transform the organisation.",
          "In our organisation, the knowledge gained from digital technology deployment is properly managed for improved productivity and innovativeness.",
          "In our organisation, knowledge management through digital technologies is continuous and knowledge sharing cut across all functions of the organisations including external actors.",
        ],
      },

      {
        variable_id: "people_3",
        element_id: "people",
        variable_name: "Reskilling of workforce",
        question: "Which of the following 'workforce reskilling' statement best fit your organisation?",
        response: "",
        options: [
          "In our organisation, plans to re-skill workers in the area of digital capability is at the early stage.",
          "In our organisation, plans to re-skill workers in the area of digital capability is at an advance stage. ",
          "In our organisation, the importance of re-skilling talented employees is well defined and plans are in place.",
          "Re-skilled employees are used in handling digital technology-related activities and improved productivity is evident in our organisation",
          "Continuous improvement in the use of re-skilled employees in digital technology-related activities is evident in our organisation",
        ],
      },

      {
        variable_id: "people_4",
        element_id: "people",
        variable_name: "Continuous learning of personnel",
        question: "Which of the following statements regarding 'personnel continous learning' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, employees work and learning process are seperated",
          "In our organisation, employees learning is integrated with employees jobs",
          "In our organisation, digital tools are used to ensure continous learning of employees",
          "Our organisation has perfected the act of learning on the job through effective use of digital tools",
          "For our organisation, learning for employees is continous.",
        ],
      },

      {
        variable_id: "people_5",
        element_id: "people",
        variable_name: "Top management support",
        question: "Which of the following statement regarding 'top management support' best fit your organisation?",
        response: "",
        options: [
          "Top management in our organisation is still sceptical about the outcomes of adopting digital technologies",
          "Top management support digital technology usage and plans are at advanced stage for digital transformation of the organisation ",
          "Top management has clear objectives for the digital transformation of our oragnsiation and plans are in place to achieve these objectives ",
          "Top management are improving on the plans put in place for digital transofrmation of our organisation ",
          "Top management of my organisation are in the forefront of digitalisation of the construction industry.",
        ],
      },

      {
        variable_id: "people_6",
        element_id: "people",
        variable_name: "Digital culture within an organisation",
        question: "Which of the following statement regarding 'digital culture' best fit your organisation?",
        response: "",
        options: [
          "Our organisation has no defined digital culture",
          "Our organisation understands the importance of havinga well defined digital culture and we are willing to learn from repeated adoption.",
          "Our organisation has a clearly defined digital culture with well spelt out policies for digital tool deployment",
          "Our organisation prioritises the use of digital tools for more effective business operations",
          "Our organisation is a digital disruptor in the construction industry.",
        ],
      },

      {
        variable_id: "people_7",
        element_id: "people",
        variable_name: "Organisation’s positive change attitude",
        question: "Which of the following statement regarding 'positive change attitude' best fit your organisation?",
        response: "",
        options: [
          "Our organisation's is mostly sceptical to embrace change when they occur",
          "Our organisation's is willing to embrace change but are mostly unsure of the outcome.",
          "Our organisation has plans and measures in place to ensure positive changes are encouraged within the working environment",
          "Our organisation embraces change and promote positive change attitude for more innovative ideas in the working environment",
          "Our organisation is always in the forefront of change both within and outside the organisation",
        ],
      },

      {
        variable_id: "people_8",
        element_id: "people",
        variable_name: "Digital empowerment of personnel",
        question: "Which of the following statement regarding 'digital empowerment' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, plans to empower employees digitally are at early stages",
          "Plans to empower employees digitally are at advance stages with some technologies available within the organisation.",
          "Our employees are empowered with the right digital tools to function in there day to day activities",
          "Our employees are empowered with the right digital tools to be innovative and productive",
          "Our organisation is a digital empowerment of employees is a continous process",
        ],
      },

      {
        variable_id: "people_9",
        element_id: "people",
        variable_name: "Personnel’s innovativeness",
        question: "Which of the following statement regarding 'personnel innovativeness' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, employees' innovativeness is not clearly defined",
          "In our organisation, employees' innovativeness is based on their self-drive",
          "Our employees are encouraged to be innovative in their day-to-day activities",
          "Our employees are given the required assistance to be innovative and productive",
          "Our employees knows the importance of innovativeness and are continously seeking new challenges",
        ],
      },

      {
        variable_id: "people_10",
        element_id: "people",
        variable_name: "Attracting and retaining digital talent",
        question: "Which of the following statement regarding 'attarcting and retaining digital talent' best fit your organisation?",
        response: "",
        options: [
          "Plans to attract and retain employees with digital talents is still at the early stage in our organisation",
          "Plans to attract and retain employees with digital talents is in advance stage in our organisation",
          "Our organisations has clear objectives in attracting employees with digital talent and measures are put in place to ensure digital talents are retained.",
          "Our organisations continous to improve on its objectives of attracting and retaining employees with digital talent ",
          "Our organisation only attarcts and retain digital talents ",
        ],
      },

      ///////begin process
      {
        variable_id: "process_1",
        element_id: "process",
        variable_name: "Digitalised planning process",
        question: "Which of the following statements regarding 'digitalised planning process' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the planning process for project delivery and business operations is not digitalised",
          "Our organisation understand the need to digitalise the planning process for project delivery and business operations. ",
          "In our organisation, the right digital tools needed to digitalise the planning process for project delivery and business operations is known, and plans are in place towards achieving this.",
          "In our organisation, the planning process for project delivery and business operations is done using digital tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the digitalisation of the planning process for project delivery and business operations is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_2",
        element_id: "process",
        variable_name: "Automated construction process",
        question: "Which of the following statements regarding 'automated construction process' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, there is a discussion on the need to automate certain operations, but plans towards achieving this is still at the infancy stage",
          "Our organisation understand the need to automate some of its operations, but the right tools needed are not completely known. ",
          "In our organisation, the right digital tools needed for the automation some of its operations is known, and plans are in place towards achieving a digitalised schedule management system",
          "In our organisation, the automation of some operations is done using defined automation tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the automation process is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_3",
        element_id: "process",
        variable_name: "Digitalised schedule management",
        question: "Which of the following statements regarding 'digitalised schedule management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, there is a discussion on the need to be digitalise and traces of the use of schedule software can be found. But this is not with the aim of digitalisation the organisation's business operations",
          "In our organisation, the need for a digitalised schedule management is understood, but this is done using some available schedule software on a trial-and-error basis. ",
          "In our organisation, the right digital tools needed for the management of schedule is known, and plans are in place towards achieving a digitalised schedule management system",
          "In our organisation, schedule of the project is managed using defined digital tools. Guidelines for the performance measurement of these technologies are in place, and evaluation is done consistently.",
          "Based on performance evaluation, continuous improvement of the schedule management system is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_4",
        element_id: "process",
        variable_name: "Digitalised resource management",
        question: "Which of the following statements regarding 'digitalised resource management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the management of resources is not digitalised, although consideration is being given to it.",
          "Currently in our organisation, resource allocation and management is done using some resource management software on a trial-and-error basis ",
          "In our organisation, the right digital tools needed for the management of resources is known, and plans are in place towards achieving a digitalised resource management system",
          "In our organisation, resources are managed using defined digital tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the resource management system is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_5",
        element_id: "process",
        variable_name: "Management of integrated service",
        question: "Which of the following statements regarding 'management of integrated service' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the concept of integrated services is not clearly understood",
          "Our organisation understands the need to digitally combine and coordinate different elements needed for optimum service delivery.",
          "In our organisation, the right digital tools (e.g BIM) needed for the management of elements in the delivery of optimum services are known and plans are in place towards achieving a digitalised integrated service management system",
          "In our organisation, integrated service is managed using appropriately defined digital tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the integrated service system is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_6",
        element_id: "process",
        variable_name: "Value delivery and quality management",
        question: "Which of the following statements regarding 'value delivery and quality management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, value delivery and quality management is not digitalised",
          "Our organisation understand the need to digitalise its value delivery and quality management system, but the right tools needed are not completely known. ",
          "The digital tools needed to digitalise the value delivery and quality management system of our organisation are known, and plans are in place towards achieving this",
          "In our organisation, value delivery and quality are managed using appropriately defined digital tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the digital management of  value delivery and quality is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_7",
        element_id: "process",
        variable_name: "Digitalised stakeholder management",
        question: "Which of the following statements regarding 'digitalised stakeholder management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the concept of digitally managing stakeholders is not clearly understood",
          "Our organisation understand the need to digitally manage its stakeholders, but the right tools needed are not completely known. ",
          "The digital tools needed to digitally manage stakeholders are known, and plans are in place towards achieving a digitalised stakeholder management system in our organisation",
          "In our organisation, stakeholders are managed using appropriately defined digital tools. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently.",
          "Continuous improvement of the digital management of stakeholders is achieved through the effective use of digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_8",
        element_id: "process",
        variable_name: "Digitalised scope management",
        question: "Which of the following statements regarding 'digitalised scope management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the management of project scope is not done digitally ",
          "Our organisation understand the need for a digitalised scope management, but the right tools needed are not completely known. ",
          "The digital tools needed for proper scope management are known, and plans are in place towards achieving a digitalised scope management system in our organisation",
          "In our organisation, project scope is managed using defined digital tools, and performance measurement of these tools is done consistently.",
          "Based on performance evaluation, continuous improvement of the project scope management is achieved through the effective use of digital digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_9",
        element_id: "process",
        variable_name: "Digitalised cost management process",
        question: "Which of the following statements regarding 'digitalised cost management process' best fit your organisation?",
        response: "",
        options: [
          "Traces of the use of cost estimating and management software can be found in our organisation. However, this is not with the aim of digitalising the whole construction process",
          "Our organisation understand the need for a digitalised cost management system, but consistency in the use of the right digital tools that can effectively help manage cost is absent. ",
          "The right technology needed for the management of cost is known , and plans are in place towards achieving a digitalised cost management system in our organisation",
          "In our organisation, cost is managed using clearly defined digital tools and the performance measurement of these toolss is done consistently.",
          "Based on performance evaluation, continuous improvement is achieved through the effective use of digital digital tools in our organisation",
        ],
      },

      {
        variable_id: "process_10",
        element_id: "process",
        variable_name: "Digitalised procurement process",
        question: "Which of the following statements regarding 'digitalised procurement process' best fit your organisation?",
        response: "",
        options: [
          "Our organisation still adopts the traditional procurement systems ",
          "There is a mixture of the use of e-procurement and the traditional approach in our organisation",
          "Our organisation has clearly defined policies on the use of e-procurement.",
          "In our organisation, resources are procured strictly using e-procurement approach. Continuous evaluation of the system is conducted for improvement",
          "Continuous improvement is achieved through the effective use of e-procurement in our organisation",
        ],
      },

      {
        variable_id: "process_11",
        element_id: "process",
        variable_name: "Digital risk management",
        question: "Which of the following statements regarding 'digital risk management' best fit your organisation?",
        response: "",
        options: [
          "Fear of unforeseen consequences of digitalisation serves as a barrier to the digital transformation of our organisation. ",
          "Our organisation understands the need to be prepared for unforeseen consequences of digitalising and we are willing to learn from past mistakes. ",
          "The right digital risk-mitigating measure to be adopted in the case of unforeseen problems is defined within our organisation ",
          "Digital risk is effectively managed using appropriate digital tools and continuous conducted in our organisation.",
          "Our organisation is continuously improving on its mitigating measures for unforeseen consequences of digitalisation of its services.",
        ],
      },

      {
        variable_id: "process_12",
        element_id: "process",
        variable_name: "Change management",
        question: "Which of the following statements regarding 'change management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, changes are handled as they come along. ",
          "Our organisation learns from previous changes and are willing to adopt digital tools needed to ease the management of these changes.",
          "Our organisation sees the importance of change management and have the digital tools necessary to manage changes.",
          "Our organisation actively manage changes and build adaptive capacity through the strategic application of digital tools.",
          "Continuous improvement of change management within our organisation is evident.",
        ],
      },

      {
        variable_id: "process_13",
        element_id: "process",
        variable_name: "Communication management",
        question: "Which of the following statements regarding 'communication management' best fit your organisation?",
        response: "",
        options: [
          "Our organisation still adopts the traditional communication approaches",
          "Our organisation understand the need for a digitalised communication system",
          "The digital tools needed for effective communication are known, and plans are in place towards achieving a digitalised communication system in our organisation",
          "In our organisation, communication is managed using defined digital tools, and performance measurement of these tools is done consistently.",
          "Based on performance evaluation, continuous improvement of communication management is achieved through the effective use of digital digital tools in our organisation",
        ],
      },
      /////////begin strategy

      {
        variable_id: "strategy_1",
        element_id: "strategy",
        variable_name: "Research and development",
        question: "Which of the following statements regarding 'research and development' best fit your organisation?",
        response: "",
        options: [
          "Our organisation does not fund R&D",
          "The support for R&D in our organisation is low. ",
          "In our organsiation, the need for R&D is defined and plans towards achieving effective R&D are in place.",
          "In our organisation, R&D is a major source of decision making, hence considerable support is given to the process.",
          "Our organisation leads the construction industry discoruse on digital transformation due to its investment in R&D.",
        ],
      },

      {
        variable_id: "strategy_2",
        element_id: "strategy",
        variable_name: "Digital technology selection",
        question: "Which of the following statements regarding 'digital technology selection' best fit your organisation?",
        response: "",
        options: [
          "In our organisation the choice of the right digital technology to use is still fuzzy",
          "In our organisation, the the choice of the right digital technology to use is known and the selection is strictly a management function.",
          "Our organisation has guidelines for the selection of digital technologies and this selection is done based on input from both management and employees",
          "Our organisation continues to improve on its guidelines for digital technology selection through proper evaluation of the performance of already adopted technologies",
          "Our organisation understand the importance of being uptodate with the latest digital technology in the quest for digital transformation",
        ],
      },

      {
        variable_id: "strategy_3",
        element_id: "strategy",
        variable_name: "Forecasting client’s need",
        question: "Which of the following statements regarding 'forecasting client’s need' best fit your organisation?",
        response: "",
        options: [
          "Our organisatin deals with clients needs as they arise",
          "Our organisation understand the needs to forcast cleints needs using digital technologies, and are prepared to learn through series of repetitive processess.",
          "In our organisation, clients needs are forecasted using digital technologies",
          "Our organisation strives to continously improve its understanding of clients needs through the improvement of its adoption of digital technologies",
          "",
        ],
      },

      {
        variable_id: "strategy_4",
        element_id: "strategy",
        variable_name: "Project and service boundary",
        question: "Which of the following statements regarding 'project and service boundary' best fit your organisation?",
        response: "",
        options: [
          "Our organisation has no defined boundary for its project and services",
          "Our organisation understand the need for a clear boundary for its services and plans are being set to achieve this.",
          "Our organisation has been able to clearly set out boundaries for its projects and services in a bid to be specialised through the sue of defined digital technologies.",
          "Our organisation continues to use digital technology to improve on its servcies within the set boundaries. Guidelines for evaluating our organsiation's performance within this set area is clear.",
          "Our organisation is leading projects and service delivery within its defined space through the use of digital technology",
        ],
      },

      {
        variable_id: "strategy_5",
        element_id: "strategy",
        variable_name: "Clients' feedback evaluation",
        question: "Which of the following statements regarding 'clients' feedback evaluation' best fit your organisation?",
        response: "",
        options: [
          "",
          "Our organisation understand the need to carefully evaluate cleints' feedback using digital technologies, and are prepared to learn through series of repetitive processess.",
          "In our organisation, clients' feedback are carefully evaluated using digital technologies",
          "Our organisation strives to continously improve its services to its clients through the improvement of the evaluation of clients feedback using digital technologies",
          "",
        ],
      },

      {
        variable_id: "strategy_6",
        element_id: "strategy",
        variable_name: "Investment in digital technology",
        question: "Which of the following statements regarding 'investment in digital technology' best fit your organisation?",
        response: "",
        options: [
          "Our organisation cannot afford to invest on digital technologies at this stage",
          "Plans to invest on digital technologies is at an advance stage in our organisation",
          "Our organisation has implemented plans to invest in digiatl technologies",
          "Our organisation is continuously increasing its investment in digital technologies. Guidelines for the performance measurement of these tools are in place, and evaluation is done consistently. ",
          "Our organisation is a digital disruptor in the construction industry as investment in emerging technologies are continuous",
        ],
      },

      {
        variable_id: "strategy_7",
        element_id: "strategy",
        variable_name: "Decision-making procedure",
        question: "Which of the following statements regarding 'decision-making procedure' best fit your organisation?",
        response: "",
        options: [
          "In our organsiation, the decision-making procedure for digital tool deployment is slow ",
          "The decision-making for digital tool deployment is subject to the outcome of the previous adoption.",
          "In our organisation, decision-making procedure is rapid and its based on the outcomes from the use of digital tools",
          "Our organisation continues to improve in its decision making through effective use of digital tools",
          "",
        ],
      },

      {
        variable_id: "strategy_8",
        element_id: "strategy",
        variable_name: "Digitalised performance measurement",
        question: "Which of the following statements regarding 'digitalised performance measurement' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, performance measurement is not digitalised",
          "Our organisation plans on digitalising its performance measurement approach. ",
          "Performance measurement in our organisation is done through the use of digital tools. ",
          "Our continues to improve on its performance measurement through the use of digital tools and guidelines for improvement are in place.",
          "Ourorganisation continues to improve in its performance due to its continuous performance measurement done using digital tools.",
        ],
      },

      {
        variable_id: "strategy_9",
        element_id: "strategy",
        variable_name: "Information and communication management",
        question: "Which of the following statements regarding 'iInformation and communication management' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, there is no effective communication of digitalisation strategies ",
          "Information on digitalisation strategy is kept on a tight leash among top management in our organisation",
          "Digitalisation strategy is clearly defined and communicated to management and employees of our organisation",
          "In our organisation, digitalisation is properly communicated to all within the organisation and external actors (such as clients)",
          "Digital strategy to be adopted in constantly being improved upon through continuous adoption of emerging technologies in our organisation.",
        ],
      },

      {
        variable_id: "strategy_10",
        element_id: "strategy",
        variable_name: "Digital alignment of business",
        question: "Which of the following statements regarding 'digital alignment of business' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the business goal is disjointed from the digital tools adopted",
          "There are some reservations regarding the  alignment of business with digitalisation in our organisation. ",
          "Our organisation have objectives towards aligning the business with the digitalisation in place.",
          "There is a technology-driven transparency through digital technology usage in our organisation. ",
          "Continuous improvement in aligning business goals with digitalisation is evident in our organisation",
        ],
      },

      {
        variable_id: "environment_1",
        element_id: "environment",

        variable_name: "Clients’ pressure",
        question: "Which of the following statements regarding 'client pressure' best fit your organisation?",
        response: "",
        options: [
          "Our organisation understands the importance of clients' pressure to adopt digital technologies, but the plans to adopt are still at the infancy stage.",
          "Our organisation understands the importance of client pressure to adopt digital technologies, and effort are being made to adopt digital technologies in deliverying servcies to our cleints",
          "A clear understanding of the ever-changing taste of clients in known, and the need to continuously implement emerging technologies to meet this demand is clearly defined in our organisation",
          "In our organisation, clients ever-changing desires are met with the adoption of emerging digital technologies needed to fulfil these desires. ",
          "In our organisation, there is continuous improvement in converting client’s pressure to adopt digital technologies into positive drive for innovativeness and digital transformation.",
        ],
      },

      {
        variable_id: "environment_2",
        element_id: "environment",
        variable_name: "Suppliers’ pressure",
        question: "Which of the following statements regarding 'supplier pressure' best fit your organisation?",
        response: "",
        options: [
          "Our organisation understands the importance of suppliers' pressure to adopt digital technologies, but plans to adopt are still at the infancy stage.",
          "Our organisation understands the importance of suppliers' pressure to adopt digital technologies, and effort are being made to ensure the adoption of digital technologies ",
          "Our organisation has a clear objective to continuously adopt emerging technologies as required by our uppliers.",
          "In our organisation, suppliers pressure are used as a positive drive to be innovative through proper digital technology deployment",
          "In our organisation, there is continuous improvement in converting suppliers' pressure to adopt digital technologies into positive drive for innovativeness and digital transformation.",
        ],
      },

      {
        variable_id: "environment_3",
        element_id: "environment",
        variable_name: "Competitors’ influence",
        question: "Which of the following statements regarding 'competitor influence' best fit your organisation?",
        response: "",
        options: [
          "Our organisation recognises that our competitors are adopting digtal technologies and plans to do the same is in progress",
          "Our organisation has put plans in place to adopt some digital technologies used by our competitors. ",
          "Our organisation has clearly defined objectives in digital technology usage in order to remain competitive within the construction industry.",
          "Our organisation is improving in its obejcetives to use digital technology in order to stay ahead of the comeptition within the construction industry.",
          "Our organisation is leading the deployment of  digital technology within the construction industry",
        ],
      },

      {
        variable_id: "environment_4",
        element_id: "environment",
        variable_name: "Government legislation",
        question: "Which of the following statements regarding 'government legislation' best fit your organisation?",
        response: "",
        options: [
          "",
          "Our organisation recognises the importance of government legislations on the deployment of digital tools for construction project delivery",
          "Our organisation adheres to the government legislations on the deployment of digital tools for construction project delivery",
          "Our organisation sees government legislation on the use of digital tools as a positive drive to be digitally transformed",
          "In our organisation, government legislations are continuously converted into a positive drive for adopting technologies",
        ],
      },

      {
        variable_id: "environment_5",
        element_id: "environment",
        variable_name: "Industry rules and regulations",
        question: "Which of the following statements regarding 'industry regulation' best fit your organisation?",
        response: "",
        options: [
          "",
          "Our organisation recognises the importance of industry rules and regulations on the deployment of digital tools for construction project delivery",
          "Our organisation adheres to the industry rules and regulations on the deployment of digital tools for construction project delivery",
          "Our organisation sees industry rules and regulations on the use of digital tools as a positive drive to be digitally transformed",
          "In our organisation, industry rules and regulations are continuously converted into a positive drive for adopting technologies",
        ],
      },

      {
        variable_id: "environment_6",
        element_id: "environment",
        variable_name: "Market scope",
        question: "Which of the following statements regarding 'market scope' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the target market of does not infleunce our descision to be digitalised",
          "Our organisation understands the importance of its target market and plans to use it to shape its digital transformation",
          "In our organisation, there are clear objectives to align our digital transformation with our target market requirement",
          "Our organisation uses its market scope to shape our digital transformation and produce defined products for its target market",
          "In our organisation, there is continuous improvement in the use of our market scope in shaping our digital tramsformation.",
        ],
      },

      {
        variable_id: "environment_7",
        element_id: "environment",
        variable_name: "Environmental impact assessment",
        question: "Which of the following statements regarding 'environmental impact assessment' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, the environmental impact does not infleunce our descision to use digital technologies and innovative processes",
          "Our organisation recognises the importance of adopting digital technologies and processes that does harm the environment",
          "Our organisation have clearly defined objectives and policies on the use of digital technologies and processes that does not harm the environment",
          "Our organisation continues to improve on its objectives and plocies on the use of digital technologies and processes that does not harm the environment",
          "Our organisation is leading the deployment of  digital technologies that create no harm to the immediate environment",
        ],
      },

      //////begin digital partnering

      {
        variable_id: "digital_partnering_1",
        element_id: "digital_partnering",
        variable_name: "Digital partner selection",
        question: "Which of the following statements regarding 'digital partner selection' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Our organisation understands the need to partner, and are willing to learn from series of partnering relationships",
          "Our organisation have clearly defined list of organisations to partner withand their complementary digital capabilities are known to us.",
          "For our organisation, careful selection of digital partners is done from within and outside the construction industry",
          "In our organisation, there is a continuous improvement of the selection of potential partners for digital partnering relationship",
        ],
      },

      {
        variable_id: "digital_partnering_2",
        element_id: "digital_partnering",
        variable_name: "Top management support for digital partnering",
        question: "Which of the following statements regarding 'top management support' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Top management supports digital partnering and plans are at advanced stages to partner with other digital oriented organisations within and outside the industry.",
          "Top management has clear objectives and policies regarding digital partnering in our oragnsiation.",
          "Top management are continuosly improving on the objectives and policies for digital partnering in our organisation ",
          "Top management of my organisation are constantly seeking new organisations to partner up with in the quest for continuous digital transformation",
        ],
      },

      {
        variable_id: "digital_partnering_3",
        element_id: "digital_partnering",
        variable_name: "Long-term commitment to digital partnering",
        question: "Which of the following statements regarding 'long-term commitment' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Since our organisation is just beginning its digital transformation joruney, partnering with other organisation for this puprose is only on a short-term bases",
          "The intention to create a long-term commitment to the digital partnering relationship is clearly defined within our organisation",
          "In our organisation, digital partnering is strategic, and the emphasis is placed on achieving digital transformation in the long run.",
          "Continuous improvement in the long-term digital partnering relationship created.",
        ],
      },

      {
        variable_id: "digital_partnering_4",
        element_id: "digital_partnering",
        variable_name: "Conflict resolution in digital partnering",
        question: "Which of the following statements regarding 'conflict resolution' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Our organisation is still at the planning phase of digital partnering, and pricinples for conflict resoultion are still at the early stages of conceptualisation",
          "Our organisation has a clearly defined laid down conflict resolution approachfor digital partnering relationship.",
          "Our organisation ensures conflict resolution is positive with a win-win approach in the mind of all digital partners",
          "There is a continuous improvement in the conflict resolution approach adopted in the digital partnering relationship",
        ],
      },

      {
        variable_id: "digital_partnering_5",
        element_id: "digital_partnering",
        variable_name: "Trust in digital partners",
        question: "Which of the following statements regarding 'trust in digital partners' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Our organisation understands the for trust, and are willing to learn from series of partnering relationships",
          "Our organisation have clearly defined principles that will help build trust in the  complementary digital capabilities of our partners",
          "Our organisation trust in the digital capabilities of our partners.",
          "In our organisation, there is a continuous improvement of trust building principles and activities, to ensure improved digital partnering relationship. ",
        ],
      },

      {
        variable_id: "digital_partnering_6",
        element_id: "digital_partnering",
        variable_name: "Common goal in the partnering process",
        question: "Which of the following statements regarding 'common goal in the partnering process' best fit your organisation?",
        response: "",
        options: [
          "In our organisation, digital partnering is not considered",
          "Our organisation understands the the need to have common goals in every partnering relationship and are willing to learn from series of partnering relationships",
          "Our organisation have clearly defined goals to be achieved from every digital partnering relationship.",
          "Our organisation continue to improve on the set goals and align them with that of our partners in every digital partnering relationship",
          "In our organisation, there is a continuous strive for a common goal in digital partnering relationship",
        ],
      },
    ];

    this.setState({ completed_dret: dret });
  };

  exit_welcome_page = () => {
    this.setState({ exit_welcome_page: false });

    setTimeout(() => this.setState({ show_current_page: "company_name" }), 1000);
  };

  exit_company_page = () => {
    if (this.state.company_name === "") {
      alert("Please input the name of your Organization");
      return;
    }
    this.setState({ exit_company_page: false });

    setTimeout(() => this.setState({ show_current_page: "statements" }), 1000);
  };

  clean_dret_of_empty_options = () => {
    for (let x = 0; x < this.state.completed_dret.length; x++) {
      for (let y = 0; y < this.state.completed_dret[x].options.length; y++) {
        if (this.state.completed_dret[x].options[y] === "") {
          this.state.completed_dret[x].options.splice(y, 1);
        }
      }
    }
  };

  get_element_definition = () => {
    let definition = "";

    if (this.state.current_element_id === "technology") {
      definition =
        "Technology refers to the planning, development, integration, and use of digital technologies in the delivery of construction organisation's services";
    }

    if (this.state.current_element_id === "people") {
      definition = "People refers to your organisation’s ability to handle its human-related issues in the attainment of digitalisation.";
    }

    if (this.state.current_element_id === "process") {
      definition = "Process refers to the effective coordination of managerial and organisation processes in service delivery of your organisation.";
    }

    if (this.state.current_element_id === "strategy") {
      definition = "Strategy refers to the strategies being adopted by your organisation towards attracting and retaining construction clients.";
    }

    if (this.state.current_element_id === "environment") {
      definition =
        "Environment dimension refers to your organisation's ability to handle environment pressures and convert them into a positive drive to achieve digitalisation.";
    }

    if (this.state.current_element_id === "digital_partnering") {
      definition =
        "Digital Partnering refers to the ability of organisations to partner with other organisations within and outside the construction industry for the sole purpose osharing digital resources and attaining digital transformation";
    }

    return definition;
  };

  get_key_elements_block = () => {
    return (
      <div class="col-lg-4 order-1 order-lg-0">
        <div class="sidebar sidebar-left">
          <div class="widget recent-posts">
            <h3 class="widget-title">DRET Elements</h3>
            <ul class="list-unstyled">
              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news1.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a>Technology</a>
                  </h4>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news2.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a href="#">People</a>
                  </h4>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news3.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a href="#">Process</a>
                  </h4>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news1.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a>Strategy</a>
                  </h4>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news2.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a href="#">Environment</a>
                  </h4>
                </div>
              </li>

              <li class="d-flex align-items-center">
                <div class="posts-thumb">
                  <a href="#">
                    <img loading="lazy" alt="img" src="images/news/news3.jpg" />
                  </a>
                </div>
                <div class="post-info">
                  <h4 class="entry-title">
                    <a href="#">Digital Partnering</a>
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  shuffle_array = (array) => {
    var m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  };

  get_next_question_and_answer_block_data = (go_back) => {
    if (this.state.current_variable_id === "") {
      let element_id = this.state.completed_dret[0].element_id;
      let variable_id = this.state.completed_dret[0].variable_id;
      let variable_name = this.state.completed_dret[0].variable_name;
      let question = this.state.completed_dret[0].question;
      let options = this.state.completed_dret[0].options;
      let selected_response = this.state.completed_dret[0].response;
      let current_index = 1;
      options = this.shuffle_array(options);

      this.setState({
        random_options: options,
        current_variable_name: variable_name,
        current_question: question,
        current_element_id: element_id,
        current_variable_id: variable_id,
        selected_response: selected_response,
        current_question_index: current_index,
      });

      return;
    }

    if (this.state.selected_response === "" && go_back !== true) {
      alert("Please Select the Approriate Response Before Moving to the Next Variable");
      return;
    } // this is needed to alert user and return when user has not selected any response

    let key = 1;
    if (go_back === true) {
      key = -1;
    } // this makes it go back instead of forward

    for (let x = 0; x < this.state.completed_dret.length; x++) {
      if (this.state.completed_dret[x].variable_id === this.state.current_variable_id) {
        if (x === 0 && key === -1) {
          return;
        } // return because this is the first question. there is no more going back

        if (x == this.state.completed_dret.length - 1) {
          // this is the last item, so we need to bring in result component

          this.setState({ show_current_page: "result" });

          return;
        }

        // set the current answer to completed dret before changing statement

        let new_completed_dret = this.state.completed_dret;
        new_completed_dret[x].response = this.state.selected_response;
        this.setState({ completed_dret: new_completed_dret });

        // change statement

        let element_id = this.state.completed_dret[x + key].element_id;
        let variable_id = this.state.completed_dret[x + key].variable_id;
        let variable_name = this.state.completed_dret[x + key].variable_name;
        let question = this.state.completed_dret[x + key].question;
        let options = this.state.completed_dret[x + key].options;
        let selected_response = this.state.completed_dret[x + key].response;
        let current_index = x + 2;
        if (go_back === true) {
          current_index = x;
        }
        options = this.shuffle_array(options);

        this.setState({
          random_options: options,
          current_variable_name: variable_name,
          current_question: question,
          current_element_id: element_id,
          current_variable_id: variable_id,
          selected_response: selected_response,
          current_question_index: current_index,
        });
        this.setState({ exit_statement: false });

        setTimeout(() => {
          this.setState({ exit_statement: true });
        }, 1000);

        // the script below takes the view port to the top of the the page
        setTimeout(() => document.getElementById("laolu_header").scrollIntoView({ behavior: "smooth" }), 1700);

        return;
      }
    }
  };

  get_selected_answer_color = (item) => {
    if (item === this.state.selected_response) {
      return "#ffb600";
    }

    return null;
  };

  set_selected_response_to_state = (item) => {
    this.setState({ selected_response: item });
  };

  get_question_and_answer_block = () => {
    return (
      <Bounce right>
        <Bounce right when={this.state.exit_statement}>
          <section id="main-container" class="main-container">
            <div class="container">
              <div class="row">
                <div>
                  <section class="call-to-action-box no-padding" style={{ margin: "0% 0 1% 0" }}>
                    <div class="container">
                      <div class="action-style-box">
                        <div class="row align-items-center">
                          <div class="col-md-8 text-center text-md-left">
                            <div class="call-to-action-text" id="evaluation_top">
                              <h3 class="action-title" style={{ color: "black", textAlign: "center" }}>
                                {" "}
                                EVALUATION For : <span style={{ color: "white" }}>{this.state.company_name}</span>
                              </h3>
                            </div>
                          </div>
                          <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                            <div class="call-to-action-btn">
                              <h4 style={{ textAlign: "center" }}>
                                Variable {this.state.current_question_index} of {this.state.completed_dret.length}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div class="col-lg-12 mb-5 mb-lg-0 order-0 order-lg-1">
                    <div class="post">
                      <div class="post-body">
                        <div class="entry-header">
                          <h4 style={{ textAlign: "center" }}>{this.state.current_question} </h4>
                        </div>

                        <div class="entry-content">
                          {this.state.random_options.map((item, index) => {
                            return (
                              <div
                                style={{
                                  border: "1px inset",
                                  marginTop: "3%",
                                  padding: "3%",
                                  backgroundColor: this.get_selected_answer_color(item),
                                }}
                                onClick={() => {
                                  this.set_selected_response_to_state(item);
                                }}
                              >
                                <b>{item} </b>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div class="post-footer" style={{ float: "left" }}>
                      <a
                        class="btn btn-primary"
                        onClick={() => {
                          this.get_next_question_and_answer_block_data(true);
                        }}
                      >
                        Previous Variable
                      </a>
                    </div>

                    <div class="post-footer" style={{ float: "right" }}>
                      <a
                        class="btn btn-primary"
                        onClick={() => {
                          this.get_next_question_and_answer_block_data();
                        }}
                      >
                        Next Variable
                      </a>
                    </div>

                    <h4 style={{ textAlign: "center" }}>
                      Variable {this.state.current_question_index} of {this.state.completed_dret.length}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Bounce>
      </Bounce>
    );
  };

  get_welcome_page = () => {
    return (
      <Bounce right>
        <Zoom when={this.state.exit_welcome_page}>
          <section id="ts-features" class="ts-features">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="ts-intro">
                    <h3 class="into-sub-title" style={{ margin: "9px", textAlign: "center" }}>
                      Digitalisation Readiness Evaluation Tool{" "}
                    </h3>
                    <h3 class="into-sub-title" style={{ textAlign: "center", color: "red" }}>
                      DRET{" "}
                    </h3>

                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: 400,
                        margin: "2% 0px 0px 0px",
                        textAlign: "center",
                      }}
                    >
                      The{" "}
                      <span style={{ color: "red" }}>
                        <b>DRET</b>
                      </span>{" "}
                      is designed to create a means for construction organisations to assess their current digital capability and improve on areas where they
                      are lacking.
                      <div class="gap-20"></div>
                      The tool proposes a five-level maturity scale for construction organisations to attain within six defined capability areas with 57
                      sub-attributes. These capability areas are technology, people, process, strategy, environment and digital partnering. Each area has
                      specific sub-attributes with questions tailored towards identifying the level of maturity of the organisation. By answering each question
                      truthfully through selecting the best fitting response, organisations will be able to know their maturity to be digitally transformed.
                      Furthermore, they will know the areas wherein more effort needs to be directed to improve the maturity level.
                      <hr />
                      At the end of the assessment, the tool gives aggregate scores for each capability area and the overall digital maturity level of the
                      organisation.
                    </p>
                  </div>

                  <div class="col-12">
                    <div class="general-btn text-center">
                      <button
                        class="btn btn-primary"
                        href="projects.html"
                        style={{
                          color: "red",
                          fontSize: "120%",
                          fontWeight: 700,
                          margin: "1% 0 0 0",
                        }}
                        onClick={() => {
                          this.setState({
                            dret_detail_info: !this.state.dret_detail_info,
                          });
                          // the settimeout function below moves the view port to the dret detail view
                          if (this.state.dret_detail_info === false) {
                            setTimeout(() => document.getElementById("dret_detail").scrollIntoView({ behavior: "smooth" }), 1000);
                          }
                        }}
                      >
                        Read More on DRET
                      </button>

                      <button
                        class="btn btn-primary"
                        href="projects.html"
                        style={{
                          color: "red",
                          fontSize: "120%",
                          fontWeight: 700,
                          margin: "1% 0 0 2%",
                        }}
                        onClick={() => this.exit_welcome_page()}
                      >
                        Evaluate Your Company With DRET
                      </button>
                    </div>

                    {this.dret_detail_info()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Zoom>
      </Bounce>
    );
  };

  dret_detail_info = () => {
    let block = null;
    if (this.state.dret_detail_info === false) {
      return null;
    }

    return (
      <div>
        <Bounce left>
          <div id="dret_detail">
            <hr />
            <p
              style={{
                fontSize: "22px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              <span style={{ color: "red", fontWeight: 900 }}>DRET</span> Scale Description{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              The DET scale is classified Under the following underlisted levels
            </p>
            <div class="gap-30"></div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              {" "}
              Initiating Level{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              In some cases, traces of digital technology usage can be found within the construction organisation. However, these digital technologies are used
              to make service delivery easier and not necessarily attain the organisation's digitalisation. Therefore, the construction organisation at this
              level is sceptical about the outcome of taking the digitalisation route.
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              {" "}
              Reapeated Level{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              There is an understanding of the concept of digitalisation, but the capability needed for its attainment is fuzzy to the organisation. The
              organisation learns from repeated actions and uses the knowledge gained from past implementation on new ones to fully grasp the whole digital
              transformation concept and process.
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              {" "}
              Defined Level{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              The capabilities needed for digitalisation is known. There are clear objectives of attaining digital transformation, and plans towards achieving
              these objectives are put in place.
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              {" "}
              Managed Level{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              The construction organisation has quantifiable goals geared towards achieving digitalisation. Emphasis is on the productivity of the different
              capability dimensions, and this is measured as part of the organisation’s measurement programme. Performance measurement at this level is
              consistent.
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                margin: "2% 0px 0px 0px",
              }}
            >
              {" "}
              Optimisation Level{" "}
            </p>

            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "2% 0px 0px 0px",
              }}
            >
              The construction organisation here is a digital disruptor of the construction industry. The organisation continuously improves its digital
              capability, promotes innovation and regularly leads the construction industry’s discussion on digital transformation.
            </p>
          </div>
        </Bounce>
        <button
          class="btn btn-primary"
          href="projects.html"
          style={{
            color: "red",
            fontSize: "120%",
            fontWeight: 700,
            margin: "1% 0 0 2%",
          }}
          onClick={() => this.exit_welcome_page()}
        >
          Evaluate Your Company With DRET
        </button>
      </div>
    );
  };

  get_company_name_page = () => {
    let padding = null;

    if (window.innerWidth > 800) {
      padding = "4% 20% 3% 20%";
    }

    return (
      <Zoom>
        <Zoom when={this.state.exit_company_page}>
          <div style={{ margin: "70px 0 70px 0", padding: padding }}>
            <section class="subscribe no-padding">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="subscribe-call-to-acton">
                      <h3>Please Input Your Organisation Name below to proceed with DRET Evaluation</h3>

                      <div class="col-md-10 newsletter-form" style={{ margin: "30px 0 30px 0" }}>
                        <div class="form-group">
                          <input
                            type="email"
                            name="email"
                            id="newsletter-email"
                            class="form-control form-control-lg"
                            placeholder="Company Name"
                            autocomplete="off"
                            onChange={(event) =>
                              this.setState({
                                company_name: event.target.value,
                              })
                            }
                          />
                        </div>

                        <div class="col-md-16 text-center text-md-right mt-3 mt-md-0">
                          <div class="call-to-action-btn">
                            <a class="btn btn-dark" onClick={() => this.exit_company_page()}>
                              Begin Evaluation
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Zoom>
      </Zoom>
    );
  };

  render() {
    //alert(dret[0].response)

    let page = this.get_welcome_page();

    if (this.state.show_current_page === "company_name") {
      page = this.get_company_name_page();

      // the script below takes the view port to the top of the the page
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    if (this.state.show_current_page === "statements") {
      page = this.get_question_and_answer_block();
    }

    if (this.state.show_current_page === "result") {
      page = <DretResultPage completed_dret={this.state.completed_dret} company_name={this.state.company_name} />;
    }

    return <div>{page}</div>;
  }
}

class DretResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.completed_dret,
      technology_score: 0,
      people_score: 0,
      process_score: 0,
      strategy_score: 0,
      environment_score: 0,
      digital_partnering_score: 0,
      total_score: 0,

      technology_count_score: 0,
      people_count_score: 0,
      process_count_score: 0,
      strategy_count_score: 0,
      environment_count_score: 0,
      digital_partnering_count_score: 0,
      total_count_score: 0,

      current_count: "technology",
      current_page: "calculating",
    };
  }

  componentDidMount() {
    setTimeout(this.calculate_result, 300);

    setTimeout(() => this.show_result_page(), 10000);

    // the script below takes the view port to the top of the the page
    document.getElementById("laolu_header").scrollIntoView({ behavior: "smooth" });
  }

  show_result_page = () => {
    this.setState({ current_page: "result" });

    // the code below starts counter reader
    setInterval(() => this.increment_score("technology"), 25);
    setInterval(() => this.increment_score("people"), 25);
    setInterval(() => this.increment_score("process"), 25);
    setInterval(() => this.increment_score("strategy"), 25);
    setInterval(() => this.increment_score("environment"), 25);
    setInterval(() => this.increment_score("digital_partnering"), 25);
    setInterval(() => this.increment_score("total"), 25);
  };

  get_collating_result_page = () => {
    let padding = null;

    if (window.innerWidth > 800) {
      padding = "4% 32% 3% 32%";
    }

    return (
      <div style={{ margin: "70px 0 70px 0", padding: padding }}>
        <section class="subscribe no-padding">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="subscribe-call-to-acton" style={{ margin: "20px 0 30px 0" }}>
                  <h3> Calculating Your Scores </h3>

                  <ContinouslyAnimate
                    animation_type="flash"
                    animation_interval={2000}
                    animate_data={
                      <div>
                        <h3 style={{ marginTop: "30px" }}>This will only take a moment ........</h3>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  calculate_result = () => {
    let answer = this.state.answer;
    let current_variable_score = 0;

    let technology_score = 0;
    let people_score = 0;
    let process_score = 0;
    let strategy_score = 0;
    let environment_score = 0;
    let digital_partnering_score = 0;
    let count = 0;
    let total_score = 0;

    for (let i = 0; i < answer.length; i++) {
      // please note that I had use unshuffled dret because the original dret options was getting shuffled when i am shuffling options and causing issues. unshuffled dret is just a duplicate of dret in the script
      for (let b = 0; b < unshuffled_dret.length; b++) {
        if (answer[i].variable_id === unshuffled_dret[b].variable_id) {
          for (let x = 0; x < unshuffled_dret[b].options.length; x++) {
            if (answer[i].response === unshuffled_dret[b].options[x]) {
              if (answer[i].element_id === "technology") {
                technology_score = technology_score + ((x + 1) / 5) * 1.636364;
              }

              if (answer[i].element_id === "people") {
                people_score = people_score + ((x + 1) / 5) * 1.8;
              }

              if (answer[i].element_id === "process") {
                process_score = process_score + ((x + 1) / 5) * 1.3846153;
              }

              if (answer[i].element_id === "strategy") {
                strategy_score = strategy_score + ((x + 1) / 5) * 1.8;
              }

              if (answer[i].element_id === "environment") {
                environment_score = environment_score + ((x + 1) / 5) * 2.57142;
              }

              if (answer[i].element_id === "digital_partnering") {
                digital_partnering_score = digital_partnering_score + ((x + 1) / 5) * 1.666666666;

                count++;
              }
            }
          }
        }
      }
    }

    total_score = technology_score + people_score + process_score + strategy_score + environment_score + digital_partnering_score;

    this.setState({
      technology_score: Math.ceil((technology_score / 18) * 100),

      people_score: Math.ceil((people_score / 18) * 100),

      process_score: Math.ceil((process_score / 18) * 100),

      strategy_score: Math.ceil((strategy_score / 18) * 100),

      environment_score: Math.ceil((environment_score / 18) * 100),

      digital_partnering_score: Math.ceil((digital_partnering_score / 10) * 100),

      total_score: Math.ceil(total_score),
    });
  };

  increment_score = (control) => {
    if (control === "technology" && this.state.technology_score > this.state.technology_count_score && this.state.current_count === "technology") {
      this.setState({
        technology_count_score: this.state.technology_count_score + 1,
      });

      if (this.state.technology_score === this.state.technology_count_score) {
        this.setState({ current_count: "people" });
      }
    }

    if (control === "people" && this.state.people_score > this.state.people_count_score && this.state.current_count === "people") {
      this.setState({ people_count_score: this.state.people_count_score + 1 });
      if (this.state.people_score === this.state.people_count_score) {
        this.setState({ current_count: "process" });
        // the script below takes the view port to the top of the the page
        if (window.innerWidth < 750) {
          document.getElementById("technology").scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    if (control === "process" && this.state.process_score > this.state.process_count_score && this.state.current_count === "process") {
      this.setState({
        process_count_score: this.state.process_count_score + 1,
      });
      if (this.state.process_score === this.state.process_count_score) {
        this.setState({ current_count: "strategy" });
        // the script below takes the view port to the top of the the page
        if (window.innerWidth < 750) {
          document.getElementById("people").scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    if (control === "strategy" && this.state.strategy_score > this.state.strategy_count_score && this.state.current_count === "strategy") {
      this.setState({
        strategy_count_score: this.state.strategy_count_score + 1,
      });
      if (this.state.strategy_score === this.state.strategy_count_score) {
        this.setState({ current_count: "environment" });
        // the script below takes the view port to the top of the the page
        if (window.innerWidth < 750) {
          document.getElementById("process").scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    if (control === "environment" && this.state.environment_score > this.state.environment_count_score && this.state.current_count === "environment") {
      this.setState({
        environment_count_score: this.state.environment_count_score + 1,
      });
      if (this.state.environment_score === this.state.environment_count_score) {
        this.setState({ current_count: "digital_partnering" });
        // the script below takes the view port to the top of the the page
        if (window.innerWidth < 750) {
          document.getElementById("strategy").scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    if (
      control === "digital_partnering" &&
      this.state.digital_partnering_score > this.state.digital_partnering_count_score &&
      this.state.current_count === "digital_partnering"
    ) {
      this.setState({
        digital_partnering_count_score: this.state.digital_partnering_count_score + 1,
      });
      if (this.state.digital_partnering_score === this.state.digital_partnering_count_score) {
        this.setState({ current_count: "total" });

        // the script below takes the view port to the top of the the page
        if (window.innerWidth < 750) {
          document.getElementById("environment").scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    if (control === "total" && this.state.total_score > this.state.total_count_score && this.state.current_count === "total") {
      this.setState({ total_count_score: this.state.total_count_score + 1 });
      if (this.state.total_score === this.state.total_count_score) {
        this.setState({ current_count: "finished" });
      }
    }
  };

  result_page = () => {
    let ipad_font_size = null;
    let ipad_font_size2 = null;
    if (window.innerWidth > 700 && window.innerWidth < 900) {
      ipad_font_size = "12px";
      ipad_font_size2 = "30px";
    }

    return (
      <div>
        <section class="call-to-action-box no-padding" style={{ margin: "1% 0 1% 0" }}>
          <div class="container">
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <h3 class="action-title" style={{ color: "black" }}>
                      DRET EVALUATION Result For : {this.props.company_name}
                    </h3>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="facts" class="facts-area dark-bg">
          <Bounce right>
            <div class="container" style={{ maxWidth: "70%", margin: "auto" }}>
              <div class="facts-wrapper">
                <div class="row">
                  <div class="col-md-2 col-sm-6 ts-facts">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/technology.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="technology">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.technology_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        Technology{" "}
                      </h3>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 ts-facts mt-5 mt-sm-0">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/fact2.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="people">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.people_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        People{" "}
                      </h3>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 ts-facts mt-5 mt-md-0">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/service-icon3.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="process">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.process_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        Process{" "}
                      </h3>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 ts-facts mt-5 mt-md-0">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/fact4.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="strategy">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.strategy_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        Strategy{" "}
                      </h3>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 ts-facts mt-5 mt-md-0">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/service-icon1.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="environment">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.environment_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        Environment{" "}
                      </h3>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-6 ts-facts mt-5 mt-md-0">
                    <div class="ts-facts-img">
                      <img loading="lazy" src="images/icon-image/service-icon4.png" alt="facts-img" />
                    </div>
                    <div class="ts-facts-content" id="digital_partnering">
                      <h2 class="ts-facts-num">
                        <span class="counterUp" style={{ fontSize: ipad_font_size2 }}>
                          {this.state.digital_partnering_count_score + "%"}
                        </span>
                      </h2>
                      <h3 class="ts-facts-title" style={{ fontSize: ipad_font_size }}>
                        Digital Partnering{" "}
                      </h3>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-12 col-sm-6 ts-facts">
                    <div
                      style={{
                        border: "solid",
                        padding: "2%",
                        width: "20%",
                        margin: "auto",
                        minWidth: "250px",
                      }}
                      id="dret_score"
                    >
                      <h3
                        style={{
                          color: "white",
                          fontSize: "200%",
                          marginBottom: "5%",
                        }}
                      >
                        DRET Score
                      </h3>
                      <h2 class="ts-facts-num">
                        <span class="counterUp" id="technology_score_counter" style={{ color: "white", fontSize: "200%" }}>
                          {this.state.total_count_score + "%"}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }}>
                  <a href="/dret" class="btn btn-primary">
                    Evaluate Another Organisation
                  </a>
                </div>
              </div>
            </div>
          </Bounce>
        </section>
      </div>
    );
  };

  render() {
    let page = this.get_collating_result_page();

    if (this.state.current_page === "result") {
      page = this.result_page();
    }

    return <div>{page}</div>;
  }
}

class SiteTitleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_url: "",
    };
  }

  componentDidMount() {
    setInterval(this.check_current_url, 200);
  }

  check_current_url = () => {
    if (window.location.pathname !== this.state.current_url) {
      this.setState({ current_url: window.location.pathname });
    }
  };

  get_shscrc_text = () => {
    if (window.innerWidth < 750) {
      return (
        <h6 style={{ textAlign: "center" }}>
          {" "}
          Sustainable Human Settlement and Construction Research Centre. <br /> Ekhaya Le <br /> DST/NRF South African Research Chair in Sustainable
          Construction Management and Leadership in the Built Environment
        </h6>
      );
    }

    return (
      <h6 style={{ textAlign: "center" }}>
        Sustainable Human Settlement and Construction Research Centre <br /> Ekhaya Le <br /> DST/NRF South African Research Chair in Sustainable Construction
        Management and Leadership in the Built Environment
      </h6>
    );
  };

  get_cidb2022_images = () => {
    if (window.innerWidth < 750) {
      return <h6 style={{ textAlign: "center", color: "red" }}>CIDB 12th Postgraduate Conference, 10th – 12th July 2022, East-London, South Africa</h6>;
    }

    return (
      <div>
        <img src="/images/cidb.jpg" />
        <img src="/images/wsu.jpg" />
        <img src="/images/uj2.jpg" />
        <h6 style={{ textAlign: "center", color: "red" }}>CIDB 12th Postgraduate Conference, 10th – 12th July 2022, East-London, South Africa</h6>;
      </div>
    );
  };

  get_sacqsp2023_images = () => {
    if (window.innerWidth < 750) {
      return <h6 style={{ textAlign: "center", color: "red" }}>CIDB 12th Postgraduate Conference, 10th – 12th July 2022, East-London, South Africa</h6>;
    }

    return (
      <div>
        <img width="90%" height="90px" src="/images/sacqsp-header.png" />
        <h6 style={{ textAlign: "center", color: "red" }}>SACQSP - 2023 Conference, 22nd – 23rd October 2023, Sandton, South Africa</h6>;
      </div>
    );
  };

  get_top_info = () => {
    if (this.state.current_url.includes("2022-cidb-conference")) {
      return this.get_cidb2022_images();
    }
    else if (this.state.current_url.includes("2023-sacqsp-conference")) {
      return this.get_sacqsp2023_images();
    }
    else if (this.state.current_url.includes("2025-sure-built-conference")) {
      return get_surebuilt2025_images();
    }
    return this.get_shscrc_text();
  };

  render() {
    return (
      <div style={{ marginTop: "1%" }}>
        <div class="bg-white">
          <div class="container">
            <div class="logo-area">
              <div class="row align-items-center">
                <div class="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                  <a class="d-block" href="/">
                    <img loading="lazy" src="images/logo.png" alt="Constra" />
                  </a>
                </div>{" "}
                {/*logo end */}
                <div class="col-lg-9 header-right">{this.get_top_info()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PrimaryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div id="laolu_header">
        <div class="site-navigation">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-dark p-0">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div id="navbar-collapse" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav mr-auto">
                      <li className="nav-item">
                        <a className="nav-link">
                          <Link to="/"> Home </Link>
                        </a>
                      </li>

                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown">
                          About Centre <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu" role="menu">
                          <li>
                            <Link to="/about-centre"> About CR Centre </Link>
                          </li>
                          <li>
                            <Link to="/centre-team">The Team </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown">
                          Publications <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu" role="menu">
                          <li>
                            <Link to="phd-studies"> PHD Research </Link>
                          </li>
                          <li>
                            <Link to="masters-studies">Masters Research</Link>
                          </li>

                          <li>
                            <Link to="industry-research"> Industry Research </Link>
                          </li>
                          <li>
                            <Link to="books">Books and Book chapters</Link>
                          </li>

                          <li>
                            <Link to="journals">Journals</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link">
                          <Link to="/ongoing-studies"> Ongoing Research </Link>
                        </a>
                      </li>

                      {/*} <li class="nav-item"> -->
                        <a class="nav-link">
                          <Link to="/dret">DRET </Link>
                        </a>
                      </li>*/}

                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown">
                          Conferences <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu" role="menu">
                          <li>
                            <Link to="/2022-cidb-conference">2022 cidb Conference </Link>
                          </li>
                          <li>
                            <Link to="/2023-sacqsp-conference">2023 SACQSP Conference </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link">
                          <Link to="/2025-sure-built-conference">SURE-Built</Link>
                        </a>
                      </li>


                      <li className="nav-item">
                        <a className="nav-link">
                          <Link to="training">Trainings</Link>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link">
                          <Link to="/contact">Contact </Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Sacqsp023ConferenceMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div id="laolu_header">
        <div class="site-navigation">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-dark p-0">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div id="navbar-collapse" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav mr-auto">
                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/2023-sacqsp-conference"> Overview </Link>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/2023-sacqsp-conference-information"> Conference Information </Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="2023-sacqsp-conference-submissions"> Submissions</Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="2023-sacqsp-conference-programme"> Programme</Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/">Back to CR Centre</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class Cidb2022ConferenceMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div id="laolu_header">
        <div class="site-navigation">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-dark p-0">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div id="navbar-collapse" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav mr-auto">
                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/2022-cidb-conference"> Overview </Link>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/2022-cidb-conference-information"> Conference Information </Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="2022-cidb-conference-submissions"> Submissions </Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="2022-cidb-conference-programme"> Programme </Link>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link">
                          <Link to="/">Back to CR Centre</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


class BigBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("header").scrollIntoView();
  }

  render() {
    return (
      <div>
        <div class="banner-carousel banner-carousel-1 mb-0">
          <div class="banner-carousel-item" style={{ backgroundImage: "url(images/slider-main/bg5.jpg)" }}>
            <div class="slider-content text-left">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                  <div class="col-md-12">
                    <h2 class="slide-title-box" data-animation-in="slideInDown">
                      {" "}
                    </h2>
                    <h3 class="slide-title" data-animation-in="fadeIn">
                      20+ Years of Research in
                    </h3>
                    <h2 class="slide-sub-title" data-animation-in="slideInLeft">
                      Construction and Engineering Industry
                    </h2>
                    <p class="slider-description lead" data-animation-in="slideInRight">
                      We Have succesfully completed several studies and have more ongoing.
                    </p>

                    <div data-animation-in="slideInLeft">
                      <Link to="phd-studies">
                        <a class="slider btn btn-primary border" aria-label="learn-more-about-us">
                          See Completed Studies
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="banner-carousel-item" style={{ backgroundImage: "url(images/slider-main/bg4.jpg)" }}>
            <div class="slider-content text-right">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                  <div class="col-md-12">
                    <h2 class="slide-title" data-animation-in="slideInDown">
                      Meet Our Team
                    </h2>
                    <h3 class="slide-sub-title" data-animation-in="fadeIn">
                      Our Skills Compliment Each Other
                    </h3>
                    <p class="slider-description lead" data-animation-in="slideInRight">
                      We Have Highly skilled proffesionals from various Industries on our Team
                    </p>
                    <div data-animation-in="slideInLeft">
                      <Link to="centre-team">
                        <a class="slider btn btn-primary border" aria-label="learn-more-about-us">
                          Meet Our Team
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="banner-carousel-item" style={{ backgroundImage: "url(images/slider-main/bg1.jpg)" }}>
            <div class="slider-content">
              <div class="container h-100">
                <div class="row align-items-center h-100">
                  <div class="col-md-12 text-center">
                    <h2 class="slide-title" data-animation-in="slideInLeft">
                      COMING SOON .....
                    </h2>
                    <h3 class="slide-sub-title" data-animation-in="slideInRight">
                    SURE-Built 2025 CONFERENCE
                    </h3>

                    <div data-animation-in="slideInLeft">
                      <Link to="/2025-sure-built-conference">
                        <a class="slider btn btn-primary border" aria-label="learn-more-about-us">
                          View Conference Information
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class FancyYellowBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section class="call-to-action-box no-padding">
          <div class="container">
            <div class="action-style-box">
              <div class="row align-items-center">
                <div class="col-md-8 text-center text-md-left">
                  <div class="call-to-action-text">
                    <p style={{ fontWeight: "600", fontSize: "20px" }}>
                      The Sustainable Human Settlement and Construction Research Centre (SHSCRC), is an entity of the{" "}
                      <span style={{ color: "red" }}>University of Johannesburg</span> within the{" "}
                      <span style={{ color: "red" }}>Department of Construction Management and Quantity Surveying </span>
                      and subject to the governance rules and structures of the{" "}
                      <span style={{ color: "red" }}>Faculty of Engineering and Built Environment. </span>
                    </p>
                  </div>
                </div>
                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div class="call-to-action-btn">
                    <img src="images/uj.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class ConferenceDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let margin = "5% 0px 0px 0px";
    let font_size = "350%";

    if (window.innerWidth < 750) {
      margin = "12% 0px 0px 0px";
      font_size = "200%";
    }

    return (
      <div>
        <section id="ts-features" class="ts-features">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ts-intro">
                  {/*<h2 class="into-title">Introducing The New</h2>*/}
                  <ContinouslyAnimate
                    animation_type={"jump"}
                    animation_delay={3000}
                    animation_interval={6000}
                    animate_data={<h3 class="into-sub-title">Coming Soon .....</h3>}
                  />

<Zoom delay={700} left>
                    <ContinouslyAnimate
                      animation_type={"shake"}
                      animation_interval={6000}
                      animate_data={
                        <p
                          style={{
                            fontSize: font_size,
                            fontWeight: 900,
                            margin: margin,
                            color: "orange",
                            textAlign: "center",
                          }}
                        >
                          SURE-Built 2025 CONFERENCE
                        </p>
                      }
                    />
                  </Zoom>

                 
                  <Zoom delay={700} left>
                    <ContinouslyAnimate
                      animation_type={"shake"}
                      animation_interval={6000}
                      animate_data={
                        <p
                          style={{
                            fontSize: "180%",
                            fontWeight: 900,
                            margin: margin,
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          Facilitating Inclusivity in Multi-, Inter-, and Transdisciplinary Sustainable Built Environment Research in Emerging Economies
                        </p>
                      }
                    />
                  </Zoom>




                  {/*<Zoom delay={700} left>*/}
                  {/*  <ContinouslyAnimate*/}
                  {/*    animation_type={"shake"}*/}
                  {/*    animation_interval={6000}*/}
                  {/*    animate_data={*/}
                  {/*      <p*/}
                  {/*        style={{*/}
                  {/*          fontSize: font_size,*/}
                  {/*          fontWeight: 700,*/}
                  {/*          margin: margin,*/}
                  {/*          color: "black",*/}
                  {/*          textAlign: "center",*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        */}
                  {/*      </p>*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</Zoom>*/}


                  <Zoom delay={1500} right>
                    <ContinouslyAnimate
                      animation_type={"flash"}
                      animation_delay={3000}
                      animation_interval={5000}
                      animate_data={
                        <h3 class="into-sub-title" style={{ margin: margin, textAlign: "center", color: "red" }}>
                          {" "}
                          4th – 5th, SEPTEMBER 2025
                        </h3>
                      }
                    />
                  </Zoom>
                  <h5 class="into-sub-title" style={{ margin: margin, textAlign: "center" }}>
                  JOHHANESBURG, SOUTH AFRICA <br />
                   
                  </h5>
                </div>
                <div class="gap-20"></div>
                <Flip delay={2000} right>
                  <div class="col-12">
                    <div class="general-btn text-center">
                      <Link to="/2025-sure-built-conference">
                        <button
                          class="btn btn-primary"
                          style={{
                            color: "red",
                            fontSize: "120%",
                            fontWeight: 700,
                          }}
                        >
                          View Confernce Information
                        </button>
                      </Link>
                    </div>
                  </div>
                </Flip>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class BelowFancyYellow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let margin = "5% 0px 0px 0px";
    let font_size = "130%";

    if (window.innerWidth < 750) {
      margin = "12% 0px 0px 0px";
      font_size = "100%";
    }

    return (
      <div>
        <section id="ts-features" class="ts-features">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ts-intro">
                  {/*<h2 class="into-title">Introducing The New</h2>*/}
                  <ContinouslyAnimate
                    animation_type={"jump"}
                    animation_delay={3000}
                    animation_interval={6000}
                    animate_data={<h3 class="into-sub-title">Introducing .....</h3>}
                  />

                  <Zoom left>
                    <ContinouslyAnimate
                      animation_type={"shake"}
                      animation_interval={6000}
                      animate_data={
                        <p
                          style={{
                            fontSize: "500%",
                            fontWeight: 900,
                            margin: margin,
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          DRET
                        </p>
                      }
                    />
                  </Zoom>

                  <h3 class="into-sub-title" style={{ margin: margin, textAlign: "center" }}>
                    Digitalisation Readiness Evaluation Tool{" "}
                  </h3>

                  <p
                    style={{
                      fontSize: font_size,
                      fontWeight: 700,
                      margin: "2% 0px 0px 0px",
                      textAlign: "center",
                    }}
                  >
                    Digital readiness is defined by the level of readiness of an organization's workforce to transition into digitized workflows that are
                    enabled by software and technology. Developed at the Construction Research Centre by Dr. Douglass Aghimien,
                    <span style={{ color: "red" }}> DRET </span> measures the digitalization readiness of a comapany.
                  </p>
                </div>

                <div class="gap-20"></div>

                <div class="col-12">
                  <div class="general-btn text-center">
                    <Link to="/dret">
                      <button
                        class="btn btn-primary"
                        style={{
                          color: "red",
                          fontSize: "120%",
                          fontWeight: 700,
                        }}
                      >
                        Access DRET
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class BlackAcheivementBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section id="facts" class="facts-area dark-bg">
          <div class="container">
            <div class="facts-wrapper">
              <div class="row">
                <div class="col-md-3 col-sm-6 ts-facts">
                  <div class="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/fact1.png" alt="facts-img" />
                  </div>
                  <div class="ts-facts-content">
                    <h2 class="ts-facts-num">
                      <span class="counterUp" data-count="19">
                        0
                      </span>
                    </h2>
                    <h3 class="ts-facts-title">Ongoing Reasearch</h3>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 ts-facts mt-5 mt-sm-0">
                  <div class="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/fact4.png" alt="facts-img" />
                  </div>
                  <div class="ts-facts-content">
                    <h2 class="ts-facts-num">
                      <span class="counterUp" data-count="48">
                        0
                      </span>
                    </h2>
                    <h3 class="ts-facts-title">Concluded Research</h3>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                  <div class="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/service-icon3.png" alt="facts-img" />
                  </div>
                  <div class="ts-facts-content">
                    <h2 class="ts-facts-num">
                      <span class="counterUp" data-count="4000">
                        0
                      </span>
                    </h2>
                    <h3 class="ts-facts-title">Man Hours</h3>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                  <div class="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/fact2.png" alt="facts-img" />
                  </div>
                  <div class="ts-facts-content">
                    <h2 class="ts-facts-num">
                      <span class="counterUp" data-count="44">
                        0
                      </span>
                    </h2>
                    <h3 class="ts-facts-title">Team Members</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class ConstructionWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section id="ts-service-area" class="ts-service-area pb-0">
          <div class="container">
            <div class="row text-center">
              <div class="col-12">
                <Zoom delay={500}>
                  <h1 class="section-sub-title">We Are Specialists In</h1>
                </Zoom>
                <p></p>
                <hr />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon1.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Theoretical Research</a>
                    </h3>
                    <p>
                      Theoretical research, also referred to as pure or basic research, focuses on generating knowledge, regardless of its practical
                      application.
                    </p>
                  </div>
                </div>

                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon2.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Explanatory Research</a>
                    </h3>
                    <p>
                      Explanatory research is the most common type of research method and is responsible for establishing cause-and-effect relationships that
                      allow generalisations to be extended to similar realities.
                    </p>
                  </div>
                </div>

                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon3.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Quantitative Research</a>
                    </h3>
                    <p>
                      Quantitative research study delves into a phenomena through quantitative data collection and using mathematical, statistical and
                      computer-aided tools to measure them.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 text-center">
                <img loading="lazy" class="img-fluid" src="images/services/service-center.jpg" alt="service-avater-image" />
              </div>
              <div class="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon5.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Exploratory Research</a>
                    </h3>
                    <p>
                      Exploratory research is used for the preliminary investigation of a subject that is not yet well understood or sufficiently researched.
                    </p>
                  </div>
                </div>

                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon6.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Experimental Research</a>
                    </h3>
                    <p>
                      It is about designing or replicating a phenomenon whose variables are manipulated under strictly controlled conditions in order to
                      identify or discover its effect on another independent variable or object.
                    </p>
                  </div>
                </div>

                <div class="ts-service-box d-flex">
                  <div class="ts-service-box-img">
                    <img loading="lazy" src="images/icon-image/service-icon4.png" alt="service-icon" />
                  </div>
                  <div class="ts-service-box-info">
                    <h3 class="service-box-title">
                      <a>Data Analysis</a>
                    </h3>
                    <p>Data analysis is the process of collecting, modeling, and analyzing data to extract insights that support decision-making.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class YellowAndBlackBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section class="subscribe no-padding">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="subscribe-call-to-acton">
                  <h3>Need to contact us? Call</h3>
                  <h4>(+27) 011-559-1860</h4>
                </div>
              </div>

              <div class="col-lg-8">
                <div class="ts-newsletter row align-items-center">
                  <div class="col-md-5 newsletter-introtext">
                    <h4 class="text-white mb-0"></h4>
                    <h4 class="text-white">For more contact Information. Visit our contact page</h4>
                  </div>

                  <div class="col-md-7 newsletter-form">
                    <div class="general-btn text-center mt-4">
                      <Link to="/contact">
                        <a class="btn btn-primary" onClick={() => document.getElementById("header").scrollIntoView()}>
                          Visit Our Contact Page
                        </a>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class BelowYellowAndBleckBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section id="news" class="news">
          <div class="container">
            <div class="row text-center">
              <div class="col-12">
                <h2 class="section-title">Recently</h2>
                <h3 class="section-sub-title">Completed Research</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="latest-post">
                  <div class="latest-post-media">
                    <a class="latest-post-img">
                      <img loading="lazy" class="img-fluid" src="images/news/news1.jpg" alt="img" />
                    </a>
                  </div>
                  <div class="post-body">
                    <h4 class="post-title">
                      <a class="d-inline-block">DEVELOPMENT OF A COST PROFILE FOR ROAD PROJECTS IN THE GHANAIAN CONSTRUCTION INDUSTRY</a>
                    </h4>
                    <div class="latest-post-meta">
                      <span class="post-item-date">
                        <i class="fa fa-clock-o"></i> November 2019
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mb-4">
                <div class="latest-post">
                  <div class="latest-post-media">
                    <a  class="latest-post-img">
                      <img loading="lazy" class="img-fluid" src="images/news/news2.jpg" alt="img" />
                    </a>
                  </div>
                  <div class="post-body">
                    <h4 class="post-title">
                      <a class="d-inline-block">BIOMIMICRY SUSTAINABILITY ASSESSMENT TOOL FOR THE SOUTH AFRICAN CONSTRUCTION INDUSTRY</a>
                    </h4>
                    <div class="latest-post-meta">
                      <span class="post-item-date">
                        <i class="fa fa-clock-o"></i> June, 2021
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mb-4">
                <div class="latest-post">
                  <div class="latest-post-media">
                    <a  class="latest-post-img">
                      <img loading="lazy" class="img-fluid" src="images/news/news3.jpg" alt="img" />
                    </a>
                  </div>
                  <div class="post-body">
                    <h4 class="post-title">
                      <a class="d-inline-block">INDOOR AIR QUALITY MANAGEMENT MODEL FOR HIGHER EDUCATIONAL INSTITUTIONS IN SOUTH AFRICA</a>
                    </h4>
                    <div class="latest-post-meta">
                      <span class="post-item-date">
                        <i class="fa fa-clock-o"></i> DECEMBER, 2020
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="general-btn text-center mt-4">
              <Link to="/phd-studies">
                <a class="btn btn-primary" onClick={() => document.getElementById("header").scrollIntoView()}>
                  View Completed PhD Research
                </a>{" "}
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <footer id="footer" class="footer bg-overlay">
          <div class="footer-main">
            <div class="container">
              <div class="row justify-content-between">
                <div class="col-lg-4 col-md-6 footer-widget footer-about">
                  <img loading="lazy" src="images/footer-logo.png" alt="cr centre" height="70px" width="250px" />
                  <p style={{ marginTop: "20px" }}>Sustainable Human Settlement and Construction Research Centre (SHSCRC), University of Johannesburg.</p>
                  <p>
                    SHSCRC's our mission is to serve as an interdisciplinary research and information source on housing (human settlement) development in South
                    Africa.
                  </p>
                  <div class="footer-social"></div>
                </div>

                <div class="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                  <div class="working-hours">
                    <img src="images/uj.png" height="100px" width="200px" />
                    <p style={{ marginTop: "20px" }}>
                      SHSCRC is an entity of the University of Johannesburg within the Department of Construction Management and Quantity Surveying and subject
                      to the governance rules and structures of the Faculty of Engineering and Built Environment.
                    </p>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                  <h3 class="widget-title">Important Links</h3>
                  <ul class="list-arrow">
                    <li>
                      <Link to="/about-centre">About Centre</Link>
                    </li>
                    <li>
                      <Link to="/2023-sacqsp-conference">SACQSP 2023 Conference</Link>
                    </li>
                    <li>
                      <Link to="/ongoing-studies">Ongoing Research</Link>
                    </li>
                    <li>
                      <Link to="/phd-studies">PhD Research</Link>
                    </li>
                    <li>
                      <Link to="/masters-studies">Masters Research</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="copyright">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="copyright-info text-center">
                    <span>Copyright &copy; CR Centre 2021,</span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="copyright-info text-center">
                    <span>
                      {" "}
                      Website designed by{" "}
                      <a href="https://www.linkedin.com/in/olaoluwa-akinshipe-49766895/" target="blank">
                        Leo
                      </a>
                    </span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="footer-menu text-center">
                    <ul class="list-unstyled mb-0">
                      <li>
                        <Link to="/about-centre">About</Link>
                      </li>
                      <li>
                        <Link to="/centre-team">Our Team</Link>
                      </li>
                      <li>
                        <Link to="/2023-sacqsp-conference">SACQSP 2023 Conference</Link>
                      </li>
                      <li>
                        <Link to="/ongoing-studies">Ongoing Research</Link>
                      </li>
                      <li>
                        <Link to="/phd-studies">PhD Research</Link>
                      </li>
                      <li>
                        <Link to="/masters-studies">Masters Reseaech</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top position-fixed">
                <button class="btn btn-primary" title="Back to Top">
                  <i class="fa fa-angle-double-up"></i>
                </button>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

class ContinouslyAnimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
    };
  }

  componentDidMount() {
    if (this.props.animation_delay !== "undefined") {
      setTimeout(() => {
        setInterval(() => {
          this.setState({ counter: this.state.counter + 1 });
        }, this.props.animation_interval);
      }, this.props.animation_delay);
    } else {
      setInterval(() => {
        this.setState({ counter: this.state.counter + 1 });
      }, this.props.animation_interval);
    }
  }

  render() {
    let animate;

    if (this.props.animation_type === "shake") {
      animate = <Shake spy={this.state.counter}>{this.props.animate_data}</Shake>;
    }
    if (this.props.animation_type === "flash") {
      animate = <Flash spy={this.state.counter}>{this.props.animate_data}</Flash>;
    }
    if (this.props.animation_type === "tada") {
      animate = <Tada spy={this.state.counter}>{this.props.animate_data}</Tada>;
    }
    if (this.props.animation_type === "pulse") {
      animate = <Pulse spy={this.state.counter}>{this.props.animate_data}</Pulse>;
    }
    if (this.props.animation_type === "jump") {
      animate = <Jump spy={this.state.counter}>{this.props.animate_data}</Jump>;
    }
    if (this.props.animation_type === "spin") {
      animate = <Spin spy={this.state.counter}>{this.props.animate_data}</Spin>;
    }
    if (this.props.animation_type === "flip") {
      animate = <Flip spy={this.state.counter}>{this.props.animate_data}</Flip>;
    }
    if (this.props.animation_type === "zoom") {
      animate = <Zoom spy={this.state.counter}>{this.props.animate_data}</Zoom>;
    }
    if (this.props.animation_type === "wobble") {
      animate = <Wobble spy={this.state.counter}>{this.props.animate_data}</Wobble>;
    }
    if (this.props.animation_type === "swing") {
      animate = <Swing spy={this.state.counter}>{this.props.animate_data}</Swing>;
    }
    if (this.props.animation_type === "rubberband") {
      animate = <RubberBand spy={this.state.counter}>{this.props.animate_data}</RubberBand>;
    }

    return <div>{animate}</div>;
  }
}

export default App;
