import React, {Component} from "react";
import {Link} from "react-router-dom";
import Bounce from "react-reveal/Bounce";

import Flash from "react-reveal/Flash";
import {get_shared_table} from "./shared-components";

class SureBuilt2025Conference extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.getElementById("header").scrollIntoView();
    }

    render() {
        let image_margin = "20px 0 0 0";
        let marginTop = "5%";

        if (window.innerWidth < 850) {
            image_margin = "-40px 0px 0px 0px";
            marginTop = "20%";
        }

        return (
            <div style={{ marginTop: "80px" }}>
                <section class="call-to-action-box no-padding">
                    <div class="container" style={{ marginBottom: "20px" }}>
                        <div class="action-style-box">
                            <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                                CONFERENCE OVERVIEW - SURE-Built 2025<br/>

                                Facilitating Inclusivity in Multi-, Inter-, and Transdisciplinary Sustainable Built Environment Research in Emerging Economies</h2>
                        </div>
                    </div>
                </section>

                <Bounce right fraction={0.01}>
                    <section id="main-container" class="main-container">
                        <div class="container" style={{marginTop: "-50px"}}>

                            {get_shared_table(image_margin)}
                            <hr/>
                            <div class="row" style={{marginTop: marginTop}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Background</h3>
                                    </Bounce>

                                    <p>The 1st Global Forum for Sustainable Built Environment Research Conference –
                                        SURE-Built with the theme "Facilitating Inclusivity in Multi-, Inter-, and
                                        Transdisciplinary Sustainable Built Environment Research in Emerging Economies",
                                        would pioneer a free-to-attend conference model focused on sustainable
                                        development within the built environment.</p>
                                    <p>
                                        Emerging economies face unique challenges in sustainable construction and
                                        environmental management. Rapid urbanisation, economic limitations, and
                                        infrastructural challenges necessitate innovative solutions that are
                                        contextually relevant. However, the knowledge and expertise to address these
                                        challenges often remain inaccessible due to paywalls and financial restrictions,
                                        leading to an underrepresentation of practical, context-specific research in
                                        traditional academic platforms. This conference will democratise access to
                                        crucial information by eliminating participation fees, enabling participants
                                        from these regions to share research, collaborate, and learn from peers
                                        worldwide.
                                    </p>
                                    <p>
                                        The rationale behind this model is rooted in inclusivity and aligned with a
                                        broader mission to promote sustainable development in line with global goals.
                                        The interdisciplinary focus encourages collaboration among architects,
                                        engineers, urban planners, environmental scientists, and policymakers, thus
                                        creating a comprehensive approach to tackling shared global challenges. This
                                        conference model does not merely aim to remove financial barriers but aspires to
                                        reshape the standard academic landscape by prioritising accessibility, equity,
                                        and actionable impact.
                                    </p>
                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Important Dates</h3>
                                    </Bounce>

                                    <ul>
                                        <li>Deadline for Submission of Abstracts - 31st January, 2025</li>

                                        <li>Notification of Acceptance of Abstracts – 1st March, 2025</li>

                                        <li>
                                            Deadline for Submission of Papers – 31st May, 2025

                                        </li>

                                        <li>Notification of Decision on Papers – 15th July, 2025</li>

                                        <li>Deadline for Submission of Draft Papers – 15th of August, 2025</li>


                                    </ul>
                                </div>
                            </div>


                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">AWARDS</h3>
                                    </Bounce>

                                    <ul>
                                        <li>Best paper award</li>

                                        <li>Best postgraduate paper award
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Publication</h3>
                                    </Bounce>
                                    <p style={{color: "red", fontWeight: "bold"}}>Conference Proceedings will be
                                        published in a Springer Edited Book series – Lecture Notes in Civil Engineering
                                        Submitted for indexing to Scopus and EI-Compendex, and Springerlink.</p>
                                    <span style={{color: "red", fontWeight: "bold"}}>Other supporting journals: </span>
                                    <p>
                                        1. International Journal of Sustainable Construction Engineering and Technology
                                        (IJSCET)

                                    </p>
                                    <p>
                                        2. Journal of Construction Project Management and Innovation
                                    </p>
                                    <p>3. Applications of Circular Economy (ACE).</p>
                                    <div style={{width:'100%'}}>
                                    <img src="images/sure-built-2025-publishers.png" alt="Image description"/>
                                </div>
                                    <hr/>
                                </div>

                            </div>

                            <div class="row" style={{marginTop: "2%"}}>
                                <div class="col-lg-12">
                                    <Bounce left>
                                        <h3 class="column-title">Conference Contact</h3>
                                    </Bounce>

                                    <p>For more information or any queries send an email to: <span
                                        style={{color: "red"}}> <b>surebuilt2025@gmail.com</b></span></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </Bounce>

                <section class="call-to-action-box no-padding">
                    <div class="container" style={{marginBottom: "20px"}}>
                    <div class="action-style-box">
                            <div class="row align-items-center">
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference-information">
                                                {" "}
                                                <a class="btn btn-dark">Conference Information</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference-submissions">
                                                {" "}
                                                <a class="btn btn-dark">Paper Submissions</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/">
                                                {" "}
                                                <a class="btn btn-dark">Back to CR Centre</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default SureBuilt2025Conference