import React, {Component} from "react";
import {Link, Route} from "react-router-dom";

class SureBuilt2025ConferenceMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div id="laolu_header">
                <div class="site-navigation">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-lg navbar-dark p-0">
                                    <button
                                        class="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse"
                                        aria-controls="navbar-collapse"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span class="navbar-toggler-icon"></span>
                                    </button>

                                    <div id="navbar-collapse" class="collapse navbar-collapse">
                                        <ul class="nav navbar-nav mr-auto">
                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <Link to="/2025-sure-built-conference"> Overview </Link>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <Link to="/2025-sure-built-conference-information"> Conference Information </Link>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <Link to="/2025-sure-built-conference-submissions"> Submissions</Link>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <Link to="/2025-sure-built-conference-programme"> Programme</Link>
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <Link to="/">Back to CR Centre</Link>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SureBuilt2025ConferenceMenu