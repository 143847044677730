
let get_surebuilt2025_images = () => {
    if (window.innerWidth < 750) {
        return <h6 style={{ textAlign: "center", color: "red" }}> Global Forum for Sustainable Built Environment Research Conference – SURE-Built, 4th - 5th Sept, 2025</h6>;
    }

    return (
        <div>
            <img width="50%" height="100px" src="/images/sure-built-2025-header.png" style={{ marginLeft: "25%"}} />
            <h6 style={{ textAlign: "center", color: "red" }}> Global Forum for Sustainable Built Environment Conference, 4th - 5th Sept, 2025</h6>
        </div>
    );
};

export default get_surebuilt2025_images