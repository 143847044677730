
let get_shared_table = (image_margin) => {

   return  <div className="row">
        <div className="col-lg-4 mt-5 mt-lg-0">
            <div id="page-slider" className="page-slider small-bg"
                 style={{margin: image_margin}}>
                <div
                    className="item"
                    style={{
                        backgroundImage: "url(images/sure-built2025-background1.png)",
                    }}
                ></div>
            </div>
        </div>
        <div className="col-lg-4">


            <h2 style={{marginTop: "50px"}}> SURE-Built 2025 <br/>4th – 5th September. </h2>


            <span style={{color: "red"}}> <h6 style={{color: "red"}}>Venue :</h6>  Online Links will be shared with participants</span>
            <br/>

            <h6 style={{marginTop: "10px", color: "blue"}}>Topic : </h6>
            <p style={{color: "blue"}}>Facilitating Inclusivity in Multi-, Inter-, and
                Transdisciplinary Sustainable
                Built
                Environment Research in Emerging Economies</p>
            <hr/>


        </div>

        <div className="col-lg-4 mt-5 mt-lg-0">
            <div id="page-slider" className="page-slider small-bg"
                 style={{margin: image_margin}}>
                <div
                    className="item"
                    style={{
                        backgroundImage: "url(images/sure-built2025-background.png)",
                    }}
                ></div>
            </div>
        </div>
    </div>
}

export {get_shared_table}