import React, {Component} from "react";
import {Link} from "react-router-dom";

import Bounce from "react-reveal/Bounce";

import Flash from "react-reveal/Flash";

class SureBuilt2025ConferenceSubmissions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.getElementById("header").scrollIntoView();
    }

    render() {
        let image_margin = "20px 0 0 0";
        let marginTop = "5%";

        if (window.innerWidth < 850) {
            image_margin = "-40px 0px 0px 0px";
            marginTop = "20%";
        }

        return (
            <div style={{ marginTop: "80px" }}>
                <section class="call-to-action-box no-padding">
                    <div class="container" style={{ marginBottom: "20px" }}>
                        <div class="action-style-box">
                            <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                                Conference Paper Submissions - SURE-Built 2025
                            </h2>
                        </div>
                    </div>
                </section>

                <Bounce right fraction={0.01}>
                    <section id="main-container" class="main-container">
                        <div className="container" style={{marginTop: "-50px"}}>

                            <div className="row" style={{marginTop: marginTop}}>
                                <div className="col-lg-12">
                                    <Bounce left>
                                        <h3 className="column-title">Abstract and Paper Submission Information </h3>
                                    </Bounce>

                                    <p>
                                        Please submit your abstract of not more than 300 words and include up to 5
                                        keywords online via the google link {" "}

                                        <a href="https://forms.gle/buZivc1EkgtoPpWM8" style={{color: "red"}}
                                           target="blank">
                                            {" "}
                                            here{" "}
                                        </a>
                                    </p>

                                    <p>
                                        The abstract template can be assessed by clicking{" "}
                                        <a href="https://tinyurl.com/23d676fa" style={{color: "red"}} target="blank">
                                            here
                                        </a>
                                    </p>

                                    <p>
                                        Abstracts will be sent to two referees.<b/>

                                        Only the authors of abstracts that pass this peer-review process will be invited
                                        to submit full papers.
                                    </p>
                                    <p>The deadline for the submission of abstracts is 23:59hrs GMT on 31st January,
                                        2025 </p>
                                </div>
                            </div>

                            <div className="row">


                                <div className="col-lg-6 mt-5 mt-lg-0">
                                    <div id="page-slider" className="page-slider small-bg"
                                         style={{margin: image_margin}}>
                                        <div
                                            className="item"
                                            style={{
                                                backgroundImage: "url(images/sure-built-2025-bar-code-1.png)",
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="col-lg-6 mt-5 mt-lg-0">
                                    <div id="page-slider" className="page-slider small-bg"
                                         style={{margin: image_margin}}>
                                        <div
                                            className="item"
                                            style={{
                                                backgroundImage: "url(images/sure-built-2025-bar-code2.png)",
                                            }}
                                        ></div>
                                    </div>
                                </div>

                            </div>

                            <div className="row" style={{marginTop: "2%"}}>
                                <div className="col-lg-12">
                                    <Bounce left>
                                        <h3 className="column-title">Guidelines for Full Paper Submission:</h3>
                                    </Bounce>
                                    <p>
                                        Full papers must not exceed 10 sides of A4 and should adhere to the conference
                                        guidelines paper template, which is available for download {" "}
                                        <a href="https://tinyurl.com/3dnaex2y" style={{color: "red"}} target="blank">
                                            here
                                        </a>
                                    </p>
                                    <p>
                                        Two members of the scientific committee will review each paper.<br/>
                                        Authors of accepted papers must be available to present the paper for it to be
                                        included in the conference proceedings.<br/>

                                        All authors can only be listed on a maximum of two papers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 mt-5 mt-lg-0">
                                <div id="page-slider" className="page-slider small-bg"
                                     style={{margin: image_margin}}>
                                    <div
                                        className="item"
                                        style={{
                                            backgroundImage: "url(images/sure-built-2025-bar-code3.png)",
                                        }}
                                    ></div>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: "2%"}}>
                                <div className="col-lg-12">
                                    <Bounce left>
                                        <h3 className="column-title">Themes and Sub-themes</h3>
                                    </Bounce>

                                    <p>
                                        The papers submitted for the conference might explore topics included but are
                                        not limited to the following::
                                    </p>


                                    <ul>
                                        <li><span style={{color: "red"}}>Construction and Project Management</span>
                                            <ul>
                                                <li>Advancements in design and construction management processes</li>
                                                <li>Construction safety and risk management</li>
                                                <li>Construction workforce management</li>
                                                <li>Knowledge management</li>
                                                <li>Lean construction</li>
                                                <li>Stakeholder management</li>

                                                <li>Theoretical and practical aspects of project and procurement
                                                    management Value management
                                                </li>
                                            </ul>
                                        </li>
                                        <li><span style={{color: "red"}}> Education in the Built Environment </span>
                                            <ul>
                                                <li>Student-centred Learning in the built environment</li>
                                                <li>Generative AI in built environment education</li>
                                                <li>Built environment education in the era of industry 5.0</li>
                                                <li>Game-based learning and gamification in the built environment</li>

                                            </ul>
                                        </li>
                                        <li><span
                                            style={{color: "red"}}> Infrastructure and industry development </span>
                                            <ul>
                                                <li>Construction companies and industry development</li>
                                                <li>Ethics and professional development</li>
                                                <li>Housing</li>
                                                <li>Infrastructure maintenance management</li>
                                                <li>Infrastructure supply chain management and procurement strategies
                                                </li>
                                                <li>Innovative financing of sustainable infrastructure</li>
                                                <li>Smart and resilient infrastructure</li>
                                            </ul>
                                        </li>
                                        <li><span style={{color: "red"}}> Sustainable Digital Built Environment </span>
                                            <ul>

                                                <li>Application of emerging digital technologies and methods</li>
                                                <li>Circular economy</li>
                                                <li>Climate change adaptation and resilience</li>
                                                <li>Digital heritage and preservation</li>
                                                <li>Indoor environmental quality and management</li>
                                                <li>Innovative building materials and technologies</li>
                                                <li>Integrated digital delivery</li>
                                                <li>Material management and environmental concerns</li>
                                                <li>Pollution in the urban environment</li>
                                                <li>Sustainable building design and refurbishment</li>
                                                <li>Sustainable construction practices</li>
                                                <li>Technology and people</li>
                                                <li>Trends in carbon emissions and energy usage</li>
                                            </ul>
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "2%"}}>
                                <div className="col-lg-12">
                                    <Bounce left>
                                        <h3 className="column-title">Contact</h3>
                                    </Bounce>

                                    <p>For more information or any queries send an email to: surebuilt2025@gmail.com</p>
                                </div>
                            </div>


                        </div>
                    </section>
                </Bounce>

                <section class="call-to-action-box no-padding">
                    <div class="container" style={{marginBottom: "20px"}}>
                        <div class="action-style-box">
                            <div class="row align-items-center">
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference">
                                                {" "}
                                                <a class="btn btn-dark">Conference Overview</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference-information">
                                                {" "}
                                                <a class="btn btn-dark">Conference Information</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/">
                                                {" "}
                                                <a class="btn btn-dark">Back to CR Centre</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SureBuilt2025ConferenceSubmissions;