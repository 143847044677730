import React, {Component} from "react";
import {Link} from "react-router-dom";
import Bounce from "react-reveal/Bounce";

import Flash from "react-reveal/Flash";
import {get_shared_table} from "./shared-components";

class SureBuilt2025ConferenceProgramme extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.getElementById("header").scrollIntoView();
    }

    render() {
        let image_margin = "-50px 0 0 0";
        let marginTop = "5%";

        if (window.innerWidth < 850) {
            image_margin = "-40px 0px 0px 0px";
            marginTop = "20%";
        }

        return (
            <div style={{ marginTop: "80px" }}>
                <section class="call-to-action-box no-padding">
                    <div class="container" style={{ marginBottom: "20px" }}>
                        <div class="action-style-box">
                            <h2 class="column-title" style={{ color: "black", textAlign: "center" }}>
                                Conference Programme - SACQSP 2023
                            </h2>
                        </div>
                    </div>
                </section>

                <Bounce right fraction={0.01}>
                    <section id="main-container" class="main-container">
                        <div class="container" style={{ marginTop: "-50px" }}>
                            <div class="row" style={{ marginTop: "4%" }}>
                                <div class="col-lg-12">
                                    <Bounce delay={1000} right>
                                        <h1 class="column-title">Conference Programme Overview Will be Available Soon</h1>
                                    </Bounce>
                                </div>
                            </div>

                        </div>
                    </section>
                </Bounce>
                <section id="main-container" class="main-container">
                    <Bounce left fraction={0.01}>{get_shared_table(image_margin)}</Bounce></section>

                <section class="call-to-action-box no-padding">
                    <div class="container" style={{ marginBottom: "20px" }}>
                        <div class="action-style-box">
                            <div class="row align-items-center">
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference">
                                                {" "}
                                                <a class="btn btn-dark">Conference Overview</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/2025-sure-built-conference-information">
                                                {" "}
                                                <a class="btn btn-dark">Conference Information</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                    <div class="call-to-action-btn">
                                        <Flash count={2}>
                                            <Link to="/">
                                                {" "}
                                                <a class="btn btn-dark">Back to CR Centre</a>{" "}
                                            </Link>
                                        </Flash>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default SureBuilt2025ConferenceProgramme